import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { useState } from 'react';
import { Facebook, Link45deg, Linkedin, Envelope, TwitterX, Whatsapp } from 'react-bootstrap-icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ShareModal = (props) => {
  const [copied, setCopied] = useState(false);
  const title = "Explore my Tata AIA profile to learn more about me and my advisory services";
  const contentToCopy = `${title}\n${props.url}`;
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };
  return (
    <Transition show={props.modalIsOpen}>
      <Dialog className="relative z-10" onClose={props.handleClose}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 max-w-xs w-full max-sm:max-w-[100%]">
           
              <div className="bg-[#fff] p-4">
                <div className={`border-b border-gray-300 pb-2 ${copied ? "mb-1" : "mb-4"} flex justify-between`}> 
                  <h2 className="text-base text-[#525252] font-bold">Share Profile</h2>
                  <button
                    onClick={props.handleClose}
                    className="text-gray-600 hover:text-gray-900"
                  >
                    &times;
                  </button>
                </div>

                {copied && <div className="rounded-md bg-green-50 py-0.5 mb-2">
                  <div className="flex">
                    <div className="m-auto">
                      <p className="text-[10px] font-medium text-green-800">Link copied to clipboard!</p>
                    </div>
                  </div>
                </div>}

                <div className="grid grid-cols-3 gap-4">
                  <CopyToClipboard text={contentToCopy} onCopy={handleCopy}>    
                    <FacebookShareButton url={props.url} quote={title}>
                      <Facebook color='#4267B2' size={40} round />
                    </FacebookShareButton>
                  </CopyToClipboard>  
                  <TwitterShareButton url={props.url} title={title}>
                    <TwitterX color='#323232' size={40} round />
                  </TwitterShareButton>
                  <CopyToClipboard text={contentToCopy} onCopy={handleCopy}>  
                    <LinkedinShareButton url={props.url} summary={title}>
                      <Linkedin color='#0762C8' size={40} round />
                    </LinkedinShareButton>
                  </CopyToClipboard>    
                  <WhatsappShareButton url={props.url} title={title}>
                    <Whatsapp color="#25D366" size={40} round />
                  </WhatsappShareButton>
                  <EmailShareButton url={props.url}  body={title}>
                    <Envelope color="#acacac" size={40} round />
                  </EmailShareButton> 
                  <div className='react-share__ShareButton'>                    
                    <CopyToClipboard text={props.url} onCopy={handleCopy}>
                      <Link45deg className='text-[45px] cursor-pointer text-white p-2 bg-[#acacac] rounded-full'/>
                    </CopyToClipboard>
                  </div>                      
                </div>
              </div> 
                  
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareModal;
