import React, { useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import "../common/common.css";
import RecaptchaComponent from '../common/RecaptchaComponent';
import { submitLeadForm } from '../../redux/actions/micrositeAction';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import useMicroSiteEncrypt from '../CustomHook/useMicroSiteEncrypt';
import { useDispatch, useSelector } from 'react-redux';

export default function ExpertAssist() {
    const dispatch = useDispatch();
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [checkFlag, setCheckFlag] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [nameError, setNameError] = useState("");
    const [countryCode, setCountryCode] = useState('');
    const [emailError, setEmailError] = useState("");
    const [mobileError, setMobileError] = useState("");
    const { microSiteEncrypt } = useMicroSiteEncrypt();
    const url_data = useSelector((state) => state.microsite?.url_data);
    const current_flow = useSelector((state) => state.microsite?.home_data?.flow);
    const customize_fields = useSelector((state) => state.microsite?.profile_data?.customization);
    
    const submitForm = () => {
        if (name === "" && customize_fields?.top_contact?.name?.validation === "required") {
            setNameError(true);
            return;
        } else if ((mobile === "" || mobileError) && customize_fields?.top_contact?.phone?.validation === "required") {
            setMobileError(true);
            return;
        } else if (emailError) {
            setEmailError(true);
            return;
        } else {
            setNameError(false);
            setEmailError(false);
            setMobileError(false);
        }
        
        let payload = {
            fullname: name,
            phoneNo: countryCode+"-"+mobile?.replace(countryCode,""),
            emailId: email,
            recaptcha_token: recaptchaToken,
            url: url_data
        };
        
        const generateToken = async () => {
            const token = await microSiteEncrypt(payload);
            if (token && token !== "") {
                dispatch(submitLeadForm(token, payload, "Consultation", current_flow, onSuccess));
            }
        };
        
        generateToken();
    };
    
    const handleCountryChange = (value) => {
        if (value) {
        try {
            const phoneNumberObj = parsePhoneNumber(value);
            const countryCode = '+' + phoneNumberObj?.countryCallingCode;
            setCountryCode(countryCode);
        } catch (error) {
            console.error('Invalid phone number', error);
        }
        } else {
        setCountryCode('');
        }
    };
    
    const onSuccess = () => {
        setName("");
        setEmail("");
        setMobile("+91");
        setCheckFlag(false);
    };
    
    return (
        <div className={`bg-[#E1DFE0] lg:max-w-5xl mx-auto border shadow-[0_8px_16px_0_rgba(0,115,187,0.10)] border-solid border-[#daeef8] relative rounded-xl my-16 mt-12 max-sm:my-8 py-12 max-sm:px-6 text-left max-sm:text-center justify-between flex max-sm:block w-full px-4 transition-all duration-500`}>
            <div className='w-1/2 max-sm:mb-8 max-sm:w-full px-14 max-sm:px-0 space-y-4 max-sm:space-y-1'>
                <p className='text-[#0073BB] text-[36px] max-sm:text-[20px] font-[TATAAIARegularPrint] not-italic font-bold leading-[35px]'>Looking for <br className='max-sm:hidden'/> expert assistance?</p>
                <p className='text-[#444] text-[18px] font-[TATAAIAPrint] max-sm:text-xs not-italic font-normal leading-[130%]'>I can help you choose the right life insurance.</p>
            </div>
            <div className='w-1/2 max-sm:w-full text-left space-y-4 max-sm:px-0 px-16'>
                <div>
                    <label htmlFor="name" className="block text-xs font-[HelveticaNeueLTStd] font-medium text-[#444]">
                        Name <span className="text-[#D62049]">{(customize_fields?.top_contact?.name?.validation === "required" ? " *" : "")}</span>
                    </label>
                    <div className="mt-2 relative">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full h-12 rounded-md font-[HelveticaNeueLTStd] border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#0073BB] sm:text-sm sm:leading-6"
                            value={name}
                            onChange={(e) => {
                                const regex = /^[a-zA-Z ]*$/;
                                if (regex.test(e.target.value)) {
                                    setName(e.target.value);
                                }  
                                if (e.target.value !== "") {
                                    setNameError(false);
                                }
                            }}
                        />
                        {nameError && <ExclamationCircleFill title="Enter a name" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5" />}
                    </div>
                </div>
                
                <div>
                    <label htmlFor="mobile" className="block text-xs font-[HelveticaNeueLTStd] font-medium text-[#444]">
                        Mobile <span className="text-[#D62049]">{(customize_fields?.top_contact?.phone?.validation === "required" ? " *" : "")}</span>
                    </label>
                    <div className="mt-1 relative country-customize-expert">
                        <PhoneInput
                            flags={flags}
                            placeholder=""
                            countryCallingCodeEditable={false}
                            international={true}
                            onChange={(e) => { 
                                handleCountryChange(e);                                 
                                if (e) {
                                  setMobile(e)
                                  if (isValidPhoneNumber(e)) {
                                      setMobileError(false); 
                                  } else {
                                      setMobileError(true); 
                                  }
                                } else {
                                  setMobile("");
                                  setMobileError(false); 
                                }                                
                            }}
                            defaultCountry="IN"
                            limitMaxLength
                        />
                        {mobileError && <ExclamationCircleFill title="Enter a valid mobile number" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5" />}
                    </div>
                </div>

                <div>
                    <label htmlFor="email" className="block font-[HelveticaNeueLTStd] text-xs font-medium text-[#444]">
                        Email
                    </label>
                    <div className="mt-2 relative">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full h-12 font-[HelveticaNeueLTStd] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#0073BB] sm:text-sm sm:leading-6"
                            value={email}
                            onChange={(e) => {
                                const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;                       
                                if (regex.test(e.target.value) || e.target.value == "") {
                                setEmailError(false);
                                } else {
                                setEmailError(true);
                                }   
                                setEmail(e.target.value)
                            }}
                        />
                        {emailError != false && <ExclamationCircleFill title="Enter a valid email" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                    </div>
                </div> 
                
                <div className="sm:col-span-3 relative flex items-start">
                    <div className="flex h-6 items-center">
                        <input
                        id="terms"
                        aria-describedby="lead-message"
                        name="terms"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-[#0073BB] focus:ring-[#0073BB]"
                        checked={checkFlag}
                        onChange={(e)=>{setCheckFlag(e.target.checked)}}
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                        <span
                        id="lead-message"
                        className="text-[#444] font-[HelveticaNeueLTStd] text-[12px] not-italic font-normal leading-[160%]"
                        >
                        TATA AIA Life Insurance Co. Ltd will send you updates on your policy, new products and services, insurance solutions or related information.
                        </span>
                    </div>
                    </div>
                    <RecaptchaComponent onVerify={setRecaptchaToken} />
                    <button
                    type="button"
                    className={`flex w-auto h-[30px] ${ checkFlag !== false ? "bg-[#D62049]":"bg-[#da4f70]"} justify-center max-sm:mx-auto items-center border px-5 pt-1 rounded-[30px]`}
                    onClick={() => {
                        console.log("recaptchaToken", recaptchaToken);
                        if (checkFlag != false) {
                        submitForm();
                        }
                    }}
                    disabled={checkFlag != true}
                    >
                    <span className="text-[#fff] font-[TATAAIARegularPrint] text-sm not-italic leading-6">
                        Get a consultation
                    </span>
                    </button>
            </div>
        </div>
    )
}
