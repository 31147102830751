import {
  UNAUTHORIZED_MICROSITE,
  TOGGLE_LOADING,
  FETCH_MAIN_DATA,
  FETCH_PROFILE_DATA,
  URL_DATA,
  AUTH_TOKEN,
  QUALIFICATION_DATA,
  SYSTEM_IP,
  FETCH_SOLUTION_TYPE_DATA
} from "../../constants/MicrositeActionTypes";

const initialState = {
  isLoading: false,
  unauthorize: null,
  home_data: null,
  profile_data: null,
  url_data: "",
  authtoken: "",
  qualification_list: null,
  system_ip: "",
  solution_data: null
};

const micrositeReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case UNAUTHORIZED_MICROSITE:
      return { ...state, unauthorize: payload };
    case FETCH_MAIN_DATA:
      return { ...state, home_data: payload };
    case TOGGLE_LOADING:
      return { ...state, isLoading: payload };
    case FETCH_PROFILE_DATA:
      return { ...state, profile_data: payload };
    case URL_DATA:
      return { ...state, url_data: payload };
    case AUTH_TOKEN:
      return { ...state, authtoken: payload };
    case QUALIFICATION_DATA:
      return { ...state, qualification_list: payload };
    case SYSTEM_IP: 
      return { ...state, system_ip: payload };
    case FETCH_SOLUTION_TYPE_DATA: 
      return { ...state, solution_data: payload };
    default:
      return state;
  }
};

export default micrositeReducer;