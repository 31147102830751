import React from 'react'
import "./PageNotFound.css"
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/pagenotfound/logo_big.png"
// import { backToPreviousRoute } from "../../redux/actions/actions";
import { useEffect } from 'react'

export default function PageNotFound() {
    const dispatch = useDispatch();
    
    return (
        <div className='main-div-404'>
            <div>
                <div id="wrapper"> 
                    <div className="container container-404">
                        <a href="/site" className="logo-link" title="back home"> 
                        <img src={logo} className="logo" alt="SalesPanda Logo"/> </a>   
                        {/* <!-- brick of wall -->             */}
                        <div className="brick"></div>    
                        {/* <!-- end brick of wall -->            <!-- Number -->          */}
                        <div className="number">              
                            <div className="four"></div>             
                            <div className="zero">                 
                                <div className="nail"></div>            
                            </div>             
                            <div className="four"></div>  
                        </div>        
                        <div className="info">    
                            <h1 className='h1-head'>Oops! Did you take a wrong turn?</h1>       
                            <p>The page you are looking for was moved, renamed or might<br/> never have existed.</p> 
                            <a href="/site" className="btn">Go Home</a>         
                        </div>      
                    </div>
                .   
                </div>
            </div>
            <footer id="footer">   
                <div className="container container-404">          
                    <div className="worker"></div>  
                <div className="tools"></div>   
                </div> 
            </footer>
        </div>
    )
}
