// RecaptchaComponent.js
import React, { useEffect } from 'react';
import Cookies from "js-cookie";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const RecaptchaComponent = ({ onVerify }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        return;
      }

      const token = await executeRecaptcha('lead_form');
      Cookies.set("r_c", token);
      onVerify(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, onVerify]);

  return null;
};

export default RecaptchaComponent;
