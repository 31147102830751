import React, { useState } from "react";
import "./section.css";
import CtaImg from "../../assets/pages/AddCta.svg";
import LeadForm from "../common/LeadForm";

const JoinTeam = ({cardData}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <div className="flex max-sm:block">
        <h3 className="font-bold max-sm:block text-left mb-4 hidden text-2xl text-gray-600">My Team</h3>
        <div className="flex w-1/2 max-sm:w-full max-sm:mb-4">
          <img className="h-full max-sm:h-auto w-3/4" src={cardData?.image} alt="team" />
          <img
            onClick={() => {
              setOpen(true);
            }}
            className="cursor-pointer w-1/4 h-full max-sm:h-auto"
            src={CtaImg}
            alt="team"
          />
        </div>
        <div className="space-y-8 max-sm:space-y-4 text-left pl-20 max-sm:p-0 max-sm:w-full w-1/2">
          <div>
            <h3 className="font-bold max-sm:hidden text-2xl text-gray-600">My Team</h3>
            <p className="text-sm text-gray-500">
              {/* {cardData?.description.slice(0, 600)}{cardData?.description?.length > 600 && "..."} */}
              {cardData?.description?.slice(0, 500).split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </p>
          </div>
        </div>
      </div>
      <LeadForm open={ open } handleClose={handleClose} formType="join advisor" source={"Join the Team"} />
    </>
  );
};

export default JoinTeam;
