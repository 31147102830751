import React from 'react';
import { useSelector } from 'react-redux';

const ReactTicker = () => {
    const ticker = useSelector((state) => state.microsite?.profile_data?.ticker);
    const currentDate = new Date();
    const targetDate = new Date('2024-10-01');
    return (
        <marquee behavior="scroll" direction="left" className="bg-[#0073BB] text-sm flex items-center text-white">
            <pre>{ticker}</pre>
        </marquee>
    );
}

export default ReactTicker;
