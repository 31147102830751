// VideoCarousel.jsx
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../carousel/carousel.css";
import { ChevronRight, Download } from "react-bootstrap-icons";
import { saveAs } from 'file-saver';
import LeadForm from "../common/LeadForm";
import { isMobile } from 'react-device-detect';

const BestSelling = ({bestSelling}) => {
  const [pdfNameData, setPdfNameData] = useState("");
  const [pdfUrlData, setPdfUrlData] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  
  const settings = {
    dots: false,
    dotsClass: "slick-dots",
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: isMobile ? bestSelling?.length > 1 : bestSelling?.length > 3,
    swipe: isMobile ? bestSelling?.length > 1 : bestSelling?.length > 3,
    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  const handleDownload = () => {
    saveAs(pdfUrlData, pdfNameData);
    setPdfNameData("");
    setPdfUrlData("");
  };

  return (
    <>
      <div
        className={`best-selling relative rounded-b-3xl pt-4 max-sm:py-6 max-sm:pb-2 w-full transition-all duration-500`}
      >
        <p className="text-[#222] text-[36px] font-[TATAAIARegularPrint] max-sm:text-2xl mb-2 max-sm:mb-0 not-italic font-semibold leading-[35px]">
          Explore our
        </p>
        <p className="text-[#222] text-[36px] font-[TATAAIARegularPrint] max-sm:text-2xl max-sm:mb-4 not-italic font-bold leading-[35px]">
          best-selling plans
        </p>
        <div className="client mx-auto lg:w-full xl:w-[90%]">
          <Slider {...settings} className="w-full py-2 max-sm:py-2 m-auto">
            {bestSelling?.map((rev, index) => (
              <div className="bg-transparent relative rounded-[10px] max-sm:p-1 p-8">
                  <div className="w-full" style={{aspectRatio:3/4}}><img src={rev?.microsite_img} className="rounded-[10px] w-full object-fill h-full" alt={"best"} /></div>
                  <div onClick={() => { setOpen(true); setPdfNameData(rev.title); setPdfUrlData(rev.content_file) }} style={{backgroundImage:"linear-gradient(to bottom right, #1574B3, #BC133D)"}} className="font-[TATAAIARegularPrint] cursor-pointer absolute w-max text-white py-1 pt-1.5 px-4 rounded-full left-1/2 transform bottom-12 max-sm:bottom-4 -translate-x-1/2 flex items-center gap-2 font-semibold justify-center text-xs">
                    <span>Download brochure</span>
                    <Download className="text-[14px]" />
                  </div>
                {/* </div> */}
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <LeadForm open={open} handleClose={handleClose} handleDownload={handleDownload} formType="best_selling" source={"Know More"} />
    </>
  );
};

export default BestSelling;
