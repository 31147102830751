import React, { useState } from 'react';
import "./section.css";
import ChatIcon from "../../assets/pages/chat2.png"
import LeadForm from '../common/LeadForm';

const AskMe = ({title, buttonTxt}) => {

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  
  return (
    <>
      <div className='flex justify-center'>    
        <div className="speak-to-me mb-10 max-sm:mt-0 p-4 flex items-center gap-8 max-sm:gap-1 max-sm:max-w-[90%] w-auto rounded-full border shadow-[0_8px_16px_0_rgba(0,115,187,0.10)] border-solid border-[#daeef8]">
          <div className='bg-white rounded-full border border-solid border-[#c5c5c5] p-3'><img src={ChatIcon} width={"30px"} alt="emote" /></div>
          <div>
            <p className='text-[#222] font-[TATAAIAPrint] max-sm:text-base text-2xl not-italic font-normal leading-[normal]'>{ title }</p>
          </div>          
          <button
            type="button"
            className="flex w-auto h-[30px] bg-[#D62049] justify-center items-center gap-2.5 shrink-0 border px-5 py-4 rounded-[30px]"
            onClick={()=>{setOpen(true)}}
          >
          <span className="text-[#fff] text-sm not-italic font-[TATAAIARegularPrint] pt-0.5 font-bold leading-5">{ buttonTxt }</span>
          </button>
        </div>
      </div>
        <LeadForm open={open} handleClose={handleClose} formType="ask me" source={"Ask Me"} />
    </>
  );
};

export default AskMe;
