//UNAUTHORIZE

export const UNAUTHORIZED_MICROSITE = 'UNAUTHORIZED_MICROSITE';

export const TOGGLE_LOADING = 'TOGGLE_LOADING';

export const FETCH_MAIN_DATA = 'FETCH_MAIN_DATA'; 

export const FETCH_PROFILE_DATA = 'FETCH_PROFILE_DATA'; 

export const URL_DATA = 'URL_DATA'; 

export const AUTH_TOKEN = 'AUTH_TOKEN'; 

export const QUALIFICATION_DATA = 'QUALIFICATION_DATA'; 

export const SYSTEM_IP = 'SYSTEM_IP'; 

export const FETCH_SOLUTION_TYPE_DATA = 'FETCH_SOLUTION_TYPE_DATA'; 
