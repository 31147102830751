import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeaderDetailData, fetchMainData, fetchQualificationData, fetchSolutionTypeData, urlUpdateHandle } from './redux/actions/micrositeAction';
import Header from './components/common/header';
import PageNotFound from './components/pages/PageNotFound';
import Footer from './components/common/footer';
import MainContainer from './components/pages/MainContainer';
import LeaderContainer from './components/pages/profile/LeaderContainer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useMicroSiteEncrypt from './components/CustomHook/useMicroSiteEncrypt';
import { Toaster } from 'react-hot-toast';
import SidhiContainer from './components/pages/profile/SidhiContainer';

function App() {
  const dispatch = useDispatch();
  const { microSiteEncrypt, token, error } = useMicroSiteEncrypt();
  const setUrl = "/site";
  const captcha_key =
    window.location.origin.includes("spdevmfp") ||
    window.location.origin.includes("localhost")
      ? "6LfunNgbAAAAAEM0X3hcwSBYKJwIqHmmInfz0uFK"
      : window.location.origin.includes("sppreprod") ||
        window.location.origin.includes("sptestmfp")
      ? "6Ldeg6YaAAAAAAZqU7AQ_j-lG4LcnwP4jfNFiCJJ"
      : "6Lf9fqoaAAAAAH8JhnTg9gLI3KsYKmR2tdCRVg8i";
  
  useEffect(() => {  
    let a = 1;
    let payload
    if (a == 1) {
      payload = "https://rishabhvatts.spdevmfp.com/showcase/?channel_type=-channel_type-&content=document_library&camp_id=SkRORk1UVXZNR0JnQ21BSw==&pid=S0RNMU1VYytSRmtrTmpOVVlBcGdDZz09&c=-contactId-"
    } else if (a == 2) {
      payload = "https://rishabhvatts.spdevmfp.com/showcase/?channel_type=-channel_type-&content=document_library&camp_id=SkRORk1Ua3ZNR0JnQ21BSw==&pid=S0RNMU1VYytSRmtrTmpOVVlBcGdDZz09&c=-contactId-"
    } else if (a == 3) {
      payload = "https://rishabhvatts.spdevmfp.com/showcase/?channel_type=-channel_type-&content=Creative&camp_id=SkROSFNURXZNR0JnQ21BSw==&pid=S0RNMU1VYytSRmtrTmpOVVlBcGdDZz09&c=-contactId-"
    } else {
      // payload = "https://pranayshah.tataaiapartner.com/site/?channel_type=WhatsApp&content=document_library&camp_id=S0RNMU1TRXNSRmxITHpOVVlBcGdDZz09&pid=SkRNMU1Ta3ZNR0JnQ21BSw==&c=&cobrand_status=VW10R1RWVXdWVDA9"
      payload = "https://annayayadav.sppreprod.in/site/?camp_id=SkROVU1UaytNR0JnQ21BSw==&content=Social&channel_type=WhatsApp&pid=S0RNMU1VY3NSRjAwTWpOVVlBcGdDZz09&c=-contactId-"
      // payload = "https://annayayadav.sppreprod.in/site/?camp_id=SkROVU1UazlVR0JnQ21BSw==&content=whatsapp&channel_type=WhatsApp&pid=S0RNMU1VY3NSRjAwTWpOVVlBcGdDZz09"
      // payload = "https://anikatesharma.sppreprod.in/site"
      // payload = "https://anikatevatts.sppreprod.in/site/?camp_id=SkROVk1TRXNVR0JnQ21BSw==&content=Social&channel_type=WhatsApp&pid=S0RNMU1VY3RKRmswTVROVVlBcGdDZz09&c=-contactId-"
      // payload = "https://rishabhvatts.spdevmfp.com/site/"
    }
    
    payload = window?.location?.href?.replace("/site","");
    
    dispatch(urlUpdateHandle(payload));
    
    const generateHomeToken = async () => {
      const tokenHome = await microSiteEncrypt();
      if (tokenHome && tokenHome !== "") {
        dispatch(fetchMainData(tokenHome, payload));
      }
    };
    
    const generateLeaderToken = async () => {
      const tokenLeader = await microSiteEncrypt();
      if (tokenLeader && tokenLeader !== "") {
        dispatch(fetchLeaderDetailData(tokenLeader, payload));
      }
    };
    
    const generateQualificationToken = async () => {
      const tokenQ = await microSiteEncrypt();
      if (tokenQ && tokenQ !== "") {
        dispatch(fetchQualificationData(tokenQ));
      }
    };
    
    const generateSolutionTypeToken = async () => {
      const tokenStype = await microSiteEncrypt();
      if (tokenStype && tokenStype !== "") {
        dispatch(fetchSolutionTypeData(tokenStype));
      }
    };
    
    generateHomeToken();
    generateLeaderToken();
    generateQualificationToken();
    generateSolutionTypeToken();
  }, []);
  
  
  useEffect(() => {
    const script = document.createElement('script');

    script.src = window.location.origin + "/chatbot/js/loadContent.js"; // URL of the script you want to load
    script.async = true; // Load the script asynchronously
    script.onload = () => {
      console.log('Script loaded successfully');
    };
    
    setTimeout(() => {
      document.body.appendChild(script);  
    }, 3000);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  return (
    <div className="App">
      <BrowserRouter basename={setUrl}>
        <GoogleReCaptchaProvider reCaptchaKey={captcha_key}>
        <Toaster position="bottom-center" reverseOrder={false} />
        {!window.location?.href?.includes("mini") ? <Header/>:""}
        <Routes>
          <Route path="/" >
            <Route path='mini' index={false} element={<SidhiContainer authToken={"authToken"} pageName="product" subPageName="conatiner"/>} />    
            <Route path='profile' index={false} element={<LeaderContainer authToken={"authToken"} pageName="product" subPageName="conatiner"/>} />
            <Route index={true} element={<MainContainer authToken={"authToken"} pageName="product" subPageName="conatiner"/>} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {!window.location?.href?.includes("mini") ? <Footer/>:""}
        </GoogleReCaptchaProvider>
      </BrowserRouter>
      <div id='chatbot' ></div>
    </div>
  );
}

export default App;
