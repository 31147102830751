import React, { useState, useRef, useEffect } from 'react';
import { ArrowDown, ChevronRight, ShareFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import ShareModal from './ShareModal';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import SmallCarousel from './SmallCarousel';

export default function MainProfile({profileData}) {
  const navigate = useNavigate();
  const location = useLocation();
  const nameRef = useRef(null); // Reference for the p tag

  const [isSmall, setIsSmall] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const descriptionRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const contentRef = useRef(null); // Ref for the content area to animate height
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const handleClose = () => {
    setModalIsOpen(false);
  }
  
  const toggleSize = () => {
    if (isSmall) {
      setIsSmall(false);
      setTimeout(() => {
        setIsContentVisible(true);
      }, 300); // Small delay before showing the content
    } else {
      setIsContentVisible(false);
      setTimeout(() => {
        setIsSmall(true);
      }, 300); // Delay to allow the content to slide out first
    }
  };

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = isMobile ? "80px":"75px";
      if (isSmall) {
        descriptionRef.current.style.height = '3.9em'; // Adjust based on the number of visible lines in desc1
      }
    }
  }, [isSmall]);
  
  const handleSmallHeight = () => {
    if (isMobile) {
      if (isSmall) {
        return 130 + nameRef?.current?.offsetHeight
      } else {
        return 380 + nameRef?.current?.offsetHeight
      }
    } else {
      if (isSmall) {
        return 220
      } else {
        return 500
      }
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      if (isSmall) {
        contentRef.current.style.height = '0'; // Slide content area out when collapsing
      } else {
        contentRef.current.style.height = `${contentRef.current.scrollHeight}px`; // Expand content area
      }
    }
  }, [isSmall]);

  return (
    <div style={{height: handleSmallHeight()}} className={`bg-[#E1DFE0] relative rounded-b-3xl pt-12 max-sm:pt-8 w-full px-16 max-sm:px-4 transition-all duration-500`}>
    <div className='mx-auto lg:max-w-7xl'>  
      <div className='justify-between flex'>
        <div className={`${isContentVisible ? "w-2/3 max-sm:w-[60%]":"w-3/4 max-sm:w-[60%]"} flex flex-col space-y-8 transition-all duration-300 ease-in-out max-sm:mb-0`}>
          <div className='max-sm:space-y-2 flex-1'>
              <div ref={nameRef}  className='text-left items-center'>
                <div className='items-center gap-8 max-sm:gap-0 max-sm:justify-between flex'>
                  <p className='text-[#000] text-[34px]  max-sm:text-xl font-[TATAAIARegularPrint] not-italic font-bold capitalize leading-[normal]'>{ profileData?.microsite?.name }</p>
                  <div onClick={()=>{setModalIsOpen(true)}} className='bg-[#D62049] h-[35px] w-[35px] max-sm:h-[30px] max-sm:w-[30px] cursor-pointer pl-2 pr-3 py-2.5 max-sm:py-1.5 rounded-full'>
                    <ShareFill className='w-[16px] text-white h-[16px] max-sm:w-[14px] bg-transparent'/>
                  </div>
                </div>
                <p className='text-[#0073BB] text-[20px] font-[TATAAIARegularPrint] mt-2 max-sm:text-sm not-italic capitalize font-semibold leading-[130%]'>{ profileData?.microsite?.designation }</p>
              </div>
            <p ref={descriptionRef} className='text-[#444] font-[HelveticaNeueLTStd] mt-2 text-[16px] max-sm:text-xs not-italic text-left font-normal leading-[19.2px] transition-height duration-500 ease-in-out overflow-hidden'>
              { profileData?.microsite?.about?.slice(0,250) } {profileData?.microsite?.about?.length > 250 && "..."}
            </p>
              
            <div className={`w-[30%] max-sm:w-full max-sm:my-4 mt-0 transition-all duration-300 ease-in-out overflow-hidden ${isContentVisible ? "height-adjust":"h-0"}`}>
              <SmallCarousel conventionData={profileData?.achivements?.data}/>
            </div>  
              
              
            <div className={`flex max-sm:hidden gap-4 mt-4 transition-all duration-300 ease-in-out overflow-hidden`} ref={contentRef} style={{ height: isContentVisible ? 'auto' : '0' }}>
              {profileData?.partner_achivements?.data?.slice(0,4)?.map((acheive, a_ind)=>{
                return (
                  <div key={a_ind} className={`gradient-shadow bg-white m-2 w-24 h-22 max-sm:w-16 max-sm:h-16 space-y-0  ${(a_ind == 1 || a_ind == 2) ? "px-4":"px-1"} p-3 py-3.5 rounded-[35px] rounded-bl-none rounded-tr-none`}>
                    <span className='font-semibold font-[TATAAIARegularPrint] text-[#0073BB] text-xl'>{ acheive?.value }</span>
                    <p className='text-xs capitalize text-[#000] font-[HelveticaNeueLTStd] font-semibold text-[10px]'>{ acheive?.key }</p>
                  </div>
                );
              })}
            </div>
          </div>    
          <div
            className={`max-sm:hidden mt-auto font-[TATAAIARegularPrint] transition-opacity duration-300 ease-in-out w-fit flex items-center gap-3 text-[#D62049] cursor-pointer text-xs not-italic font-bold leading-[normal] tracking-[-0.36px] uppercase ${isContentVisible ? 'opacity-100' : 'opacity-0'}`}
            onClick={() => {
              const newUrl = `/profile?${queryParams?.toString()}`
              navigate(newUrl);
            }}
          >
            <span>Full Profile</span>
            <ChevronRight className='text-xs'/>
          </div>
        </div>
        <div className="w-1/4 max-sm:w-[35%] flex justify-end">
          <div className={`relative transition-all duration-500 ease-in-out ${!isSmall ? 'w-full h-auto' : 'max-sm:w-32 max-sm:h-32 w-28 h-28 rounded-full overflow-hidden'}`}>
            <div className={`absolute inset-0 bg-transparent transition-opacity duration-500 ease-in-out ${!isSmall ? 'opacity-0' : 'opacity-100'} rounded-full`}></div>
            <img
              src={profileData?.microsite?.profile_image}
              alt="Profile"
              className={`transition-transform gradient-shadow-header duration-500 ease-in-out rounded-3xl ${
                !isSmall
                  ? 'w-full h-auto object-cover transform scale-110'
                  : 'w-full h-32 object-cover transform scale-100'
              }`}
              style={{
                objectPosition: 'top', // Ensure image expands from the top
                transformOrigin: 'top', // Set transformation origin to top
              }}
            />
          </div>
        </div>
      </div>
      <div className={`max-sm:flex hidden transition-all mt-2 duration-300 ease-in-out overflow-hidden`} ref={contentRef} style={{ height: isContentVisible ? 'auto' : '0' }}>
        {profileData?.partner_achivements?.data?.slice(0,4).map((acheive, a_ind)=>{
            return (
              <div key={a_ind} className={`gradient-shadow bg-white m-1 w-[5rem] h-[4.5rem] space-y-0 p-3 ${(a_ind == 1 || a_ind == 2) ? "px-4":"px-1"} rounded-[35px] rounded-bl-none rounded-tr-none`}>
                  <span className='font-semibold text-[#0073BB] max-sm:text-sm text-2xl mb-4'>{acheive?.value}</span>
                  <p className='text-sm font-semibold max-sm:text-[8.5px] font-[TATAAIAPrint] capitalize text-[#000] max-sm:leading-3'>{ acheive?.key}</p>
              </div>
            );
          })}  
        </div>
      <div
        className={`pt-6 mb-12 max-sm:flex font-[TATAAIARegularPrint] max-sm:justify-center hidden transition-opacity duration-300 ease-in-out w-full items-center gap-3 text-[#D62049] cursor-pointer text-xs not-italic font-bold leading-[normal] tracking-[-0.36px] uppercase ${isContentVisible ? 'opacity-100' : 'opacity-0'}`}
        onClick={() => {
          const newUrl = `/profile?${queryParams?.toString()}`
          navigate(newUrl);
        }}
      >
        <span className='mt-1'>Full Profile</span>
        <ChevronRight className='text-xs'/>
      </div>
      <span
        onClick={toggleSize}
        className={`${isSmall ? "bg-[#0073BB] -bottom-5 left-1/2" : "bg-[#D62049] -bottom-5 left-1/2"} transform -translate-x-1/2 cursor-pointer absolute rounded-full`}
      >
        <ArrowDown className={`p-2 text-[40px] transition-all text-white duration-500 ease-in-out ${!isSmall ? "rotate-180" : ""}`} />
      </span>
      
      <ShareModal modalIsOpen={ modalIsOpen } handleClose={handleClose} url={window.location.origin + window.location.pathname + "profile/"} />
    </div>
    </div>
  );
}
