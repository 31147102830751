import React, { useState } from 'react';
import "./section.css";
import Emote from "../../assets/pages/emoji3.png"
import LeadForm from '../common/LeadForm';

const SpeakToMe = ({ title, subTitle, buttonTxt }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <div className='flex justify-center mx-auto lg:max-w-7xl'>    
          <div className="speak-to-me bg-[#E1DFE0] mt-10 mb-20 max-sm:mt-0 max-sm:mb-10 py-6 flex max-sm:items-center gap-12 max-sm:gap-4 w-[80%] max-sm:w-[90%] px-24 max-sm:px-4 border shadow-[0_8px_16px_0_#E1DFE0] rounded-[20px] border-solid border-[#daeef8]">
            <div className='bg-white h-fit rounded-full'><img src={Emote} className='w-[120px] rounded-full max-sm:w-40' alt="emote" /></div>
            <div className='text-left'>
            <p className='text-[#0073BB] text-[28px] font-[TATAAIARegularPrint] max-sm:text-xl mb-2 not-italic font-bold leading-[normal] max-sm:leading-6'>{ title }</p>
            <p className='text-[#444] mb-6 max-sm:mb-2 text-[16px] font-[TATAAIAPrint] max-sm:text-xs not-italic text-left font-semibold leading-[120%]'>{ subTitle }</p>
              <button
                type="button"
                onClick={()=>{setOpen(true)}}
                className="flex w-auto bg-[#D62049] justify-center font-[TATAAIARegularPrint] items-center gap-2.5 shrink-0 border px-5 py-1.5 pb-1 rounded-[30px]"
              >
                <span className="text-[#fff] text-[14px] not-italic font-[700] leading-5">{buttonTxt}</span>
              </button>
            </div>          
          </div>
      </div>
      <LeadForm open={open} handleClose={handleClose} formType="speak to me" source={ "Let's Discuss" } />
    </>
  );
};

export default SpeakToMe;
