// VideoCarousel.jsx
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carousel.css';
import LeadForm from '../common/LeadForm';
import { isMobile } from 'react-device-detect';


const CarouselSecondary = ({ cardData, currentFlow }) => {  
  const sliderRef = useRef(null);
  const [timeOver, setTimeOver] = useState(false);
  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  }
  
  const settings = {
    dots: true,
    dotsClass: "slick-dots",
    centerMode: false,
    centerPadding: '0px',
    infinite: cardData?.length > 2 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 3000,
    draggable: isMobile ? true : cardData?.length > 2,
    swipe: isMobile ? true : cardData?.length > 2,
    ...cardData.length > 2 && {initialSlide: isMobile ? 0 : cardData?.length - 1} ,
    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices
        settings: {
          centerMode: true,
          centerPadding: '20px',
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    beforeChange: (current) => {
      setTimeOver(false);
    },
  };
  
  useEffect(() => {
    setTimeOver(true);
  }, [])
  
  
  return (
    <>
      <div className={`top-carousel ${isMobile ? "primary-carousel" : cardData?.length > 2 ? "secondary-carousel" : "one-card-carousel"}`}>
        <Slider ref={sliderRef} {...settings} className='w-full py-0 max-sm:py-0 m-auto'>
          {cardData?.map((card, index) => (
            <div className={`${isMobile && "mt-5"}`} key={index}>
              <div className="max-w-sm relative lg:w-[240px] xl:w-[280px] max-sm:w-[218px] mx-auto shadow-none bg-transparent">
                <div className="w-full lg:h-[22rem] relative xl:h-[26rem] max-sm:h-[21rem] my-4 max-sm:my-12 bg-cover bg-center">
                  <img className='h-full object-fill' src={card.image} alt='card' />
                  {(cardData?.length == (index + 1)) && <p onClick={() => { setOpen(true)}} className={`absolute ${currentFlow == "product" ? "top-[36%]":"top-[32%]"} cursor-pointer left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold bg-[#D62049] text-white px-4 font-[TATAAIARegularPrint] py-1 max-sm:text-[9px] text-xs rounded-full`}>Yes, I do</p>}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <LeadForm open={open} handleClose={handleClose} formType="secondary" source={"Yes I Do"} />
      </div>
    </>
  );
};

export default CarouselSecondary;