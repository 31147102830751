// VideoCarousel.jsx
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carousel.css'; // Custom CSS for styling
import QuoteImg from "../../assets/pages/quote.png"
import { ArrowRightIcon } from '@heroicons/react/16/solid';
import { ChevronRight, X } from 'react-bootstrap-icons';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import ProfileImg from "../../assets/modal/profile.png";
import BgImg from "../../assets/modal/quotes.png";
import { isMobile } from 'react-device-detect';


const ClientSwiper = ({ review }) => {  
  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const settings = {
    dots: isMobile? false: true,
    dotsClass: "slick-dots",
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: isMobile ? true : review?.length > 3,
    swipe: isMobile ? true : review?.length > 3,
    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices
        settings: {
          slidesToShow: review?.length > 1 ? 1.5 : 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  
  return (
    <>
      <div className="client">
        <Slider {...settings} className='w-full py-12 max-sm:pb-0 max-sm:pt-0 m-auto'>
          {review?.map((rev, index) => (
            <div key={index} className="bg-[#E1DFE0] relative rounded-2xl h-[335px] max-sm:h-[320px] text-gray-600 p-8 max-sm:p-6">
              <img src={QuoteImg} alt="" className='max-sm:w-8'/>  
              <div className='my-8 max-sm:my-4'>
                <p className='text-xs font-[HelveticaNeueLTStd] mb-4 text-left'>{rev.message?.slice(0, 250)}{ rev.message?.length > 300 && "..." }</p>
                <p className='text-left text-[#0073BB] font-[TATAAIARegularPrint] w-full text-xs'>- {rev.name}, { rev.designation !== "" ? rev.designation : rev.company }</p>
              </div>
              <div className='flex absolute bottom-[25px] right-[35px] text-[10px] text-[#D62049] font-bold font-[TATAAIARegularPrint] cursor-pointer items-center gap-1 justify-end'><span onClick={() => { setPopupData(rev);  setOpen(true)}} >Read more</span><ChevronRight className='text-[10px]'/></div>
            </div>
          ))}
        </Slider>
      </div>
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-[#E1DFE0] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                  <div className='bg-no-repeat bg-[65px] space-y-8 p-8' style={{ backgroundImage: `url(${BgImg})`, backgroundPositionY: "20%" }}>
                    <X className="absolute right-2 top-2 z-50 text-[#222] cursor-pointer text-2xl" onClick={() => setOpen(false)} />
                    <div className='space-y-2'>
                      <div className='flex justify-center'>
                        <img className='rounded-full' src={popupData?.image} alt="No content available" />
                      </div>
                      <p className='w-ful font-[TATAAIARegularPrint] font-semibold text-center text-[10px]'>{popupData?.name}, { popupData?.designation !== "" ? popupData?.designation : popupData?.company }</p>
                    </div>
                    <div className='space-y-8 font-[HelveticaNeueLTStd]  text-sm text-[#222]'>
                      {popupData?.message}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ClientSwiper;