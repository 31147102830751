// VideoCarousel.jsx
import React, { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carousel.css'; // Custom CSS for styling
import CertficateImage from "../../assets/carousel/certi_img.png";
import { isMobile } from 'react-device-detect';

const TrainingCarousel = ({ cardData }) => {  
  const sliderRef = useRef(null);
  const settings = {
    dotsClass: "slick-dots",
    infinite: false,
    speed: 500,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: isMobile ? cardData?.length > 1 : cardData?.length > 3,
    swipe: isMobile ? cardData?.length > 1 : cardData?.length > 3,
    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
  };
  
  return (
    <>
      <div className="common">
        <Slider ref={sliderRef} {...settings} className='w-full max-sm:py-0 m-auto'>
          {cardData?.length > 0 && cardData?.map((item,index) => {
              return (
                <div key={index}>
                  <div className='flex justify-center mx-auto'>
                    <div
                      onClick={() => {
                        if (item?.url && item?.url != "") {
                          let anchor = document.createElement('a');
                          anchor.href = item?.url;
                          anchor.target = '_blank';
                          document.body.appendChild(anchor);
                          anchor.click();
                        }
                      }}
                      className='flex cursor-pointer gap-2 items-center'
                    >
                      <img src={CertficateImage} className='w-14' alt={"certificate"} />
                      <div className='text-left max-sm:pr-0 pr-8'>
                        <p title={item?.title} className='font-semibold font-[TATAAIAPrint] w-full max-sm:w-full text-[#222] text-sm max-sm:text-base'>{item?.title}</p>
                        <p title={ item?.description } className='font-normal font-[HelveticaNeueLTStd] text-[#757575] max-sm:text-sm text-xs'>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </Slider>
      </div>
    </>
  );
};

export default TrainingCarousel;