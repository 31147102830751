import React from 'react';

export default function DetailCircle({profileData}) {
  return (
    <>
        <div className="grid grid-cols-4 gap-12 max-sm:gap-3 transition-all duration-500 ease-in-out">
            {profileData?.map((acheive, a_ind)=>{
              return (
                <>
                  {a_ind < 4 ? <div className={`gradient-shadow-lg bg-white w-[7.5rem] ${(a_ind == 1 || a_ind == 2) ? "max-sm:px-4 px-6":"px-1 max-sm:px-1.5"} h-28 max-sm:w-20 max-sm:h-20 space-y-1 max-sm:space-y-1 p-3 max-sm:p-1.5 py-4 border border-[#d7d7d7] max-sm:py-3 rounded-[35px] rounded-bl-none rounded-tr-none`}>
                    <span className='font-bold text-[#0073BB] font-[TATAAIARegularPrint] capitalize max-sm:text-sm text-3xl mb-4'>{acheive?.value}</span>
                    <p className='text-[16px] font-[HelveticaNeueLTStd] capitalize text-[#000] max-sm:text-[9px] leading-[18px] max-sm:leading-3 font-semibold'>{acheive?.key}</p>
                  </div>
                    : ""}    
                </>
              );
            })}      
        </div>
    </>
  );
} 
