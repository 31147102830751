import React, { useState } from 'react'
import { Star, StarFill } from 'react-bootstrap-icons';

export default function About({aboutData,nps}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border-y-[#d6d6d6] px-16 max-sm:px-0 py-8 border-l-0 border-r-0 mb-8 border-b border-solid border-t">
      <div className='mb-4 space-y-4 max-sm:space-y-1 max-sm:mb-2'>
        <div>
          <p className='text-[#444] font-[TATAAIARegularPrint] text-3xl max-sm:text-2xl not-italic font-bold leading-[normal]'>About me</p>
          {(nps && nps?.value != "" && nps?.value != "null") && <p className='text-[#444] flex justify-center items-center gap-3 text-xl font-[TATAAIAPrint] max-sm:text-lg not-italic font-bold leading-[normal]'>
              <span className='mt-1'>{nps?.key}:</span>
              <div className='flex gap-1'>
                <StarFill color="#fec400" />
                <StarFill color="#fec400" />
                <StarFill color="#fec400" />
                <StarFill color={nps?.value > 59 ? "#fec400" : "#eceaea"} />
                <StarFill color={nps?.value > 79 ? "#fec400" : "#eceaea"} />
              </div>
        </p>}
        </div>
        <div className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isExpanded ? 'max-h-screen' : 'max-h-20 max-sm:max-h-36'}`}>
          <p className='text-[#444444] font-[HelveticaNeueLTStd] text-sm not-italic text-justify font-normal'>
            {aboutData?.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </p>
        </div>
      </div>
      <button onClick={toggleExpand} className='text-[11px] font-[TATAAIARegularPrint] font-bold text-[#D62049]'>
        {isExpanded ? 'Read less' : 'Read more'}
      </button>
    </div>
  );
}
