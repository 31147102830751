// VideoCarousel.jsx
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Flower from "../../assets/pages/flower_design.png"
import { Usb } from 'react-bootstrap-icons';
import './carousel.css'; // Custom CSS for styling
import { isMobile } from 'react-device-detect';


const CommonCarousel = ({ type, cardData }) => {  
  const sliderRef = useRef(null);
  const settings = {
    dotsClass: "slick-dots",
    infinite: false,
    speed: 500,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: isMobile ? cardData?.length > 2 : cardData?.length > 4,
    swipe: isMobile ? cardData?.length > 2 : cardData?.length > 4,
    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        }
      }
    ],
  };
  
  const handleCardClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };
  
  return (
    <>
      <div className="common">
        <Slider ref={sliderRef} {...settings} className='w-full pt-10 max-sm:py-0 m-auto'>
          {cardData?.map((card, index) => (
            <div key={index} onClick={() => handleCardClick(index)}>
              {type == "convention" ? <div>
                    <div className='w-[200px] max-sm:w-[120px] mx-auto'><img src={card.image} alt={"achieve"} /></div>
                    <p className='font-semibold mt-3 w-full font-[TATAAIAPrint] text-center text-[#222] max-sm:text-[13px] leading-4 text-[14px]'>{ card.title }</p>
                    <p className='font-normal text-[#222] font-[HelveticaNeueLTStd] text-xs'>{ card.year }</p>
                    {/* <p className='font-normal text-[#222] text-[10px]'>FY'20</p> */}
                </div>
              :
                <div>
                  <div className='relative'>
                    <img src={Flower} className='mx-auto w-[60%]' alt={"flower"} />
                    <span className='absolute left-1/2 font-semibold top-1/2 font-[HelveticaNeueLTStd] transform -translate-y-1/2 -translate-x-1/2 max-sm:text-[12px] text-[17px] bg-[#fff] text-[#000]'>{ card.year }</span>
                  </div>  
                  <p className='mx-auto font-[TATAAIAPrint] w-[80%] mt-3 text-[#222] max-sm:text-sm'>{card.title}</p>
                  {/* <div className='flex justify-center'><Usb className='text-[28px] text-[#4f4f4f]'/></div> */}
                </div>
              }
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default CommonCarousel;