import React, { useRef, useState } from 'react';
import AwardImg from "../../assets/pages/Award.png"
import Flower from "../../assets/pages/flower_design.png"
import ProfileSwiper from '../carousel/ProfileSwiper';

export default function SmallCarousel({ conventionData }) {
  const slides = conventionData?.map((item, index) => (
    <div className='flex items-center gap-2' key={index} onClick={() => console.log(index)}>
      <div className='relative w-20 h-16'>
        <img
          className='w-full h-full object-fill'
          alt="img"
          src={Flower}
        />
        <span className='absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 text-[12px] max-sm:text-[10px] bg-[#e1dfe0] font-[HelveticaNeueLTStd] text-[#000]'>{ item.year }</span>  
      </div>
      <p className='text-[12px] w-3/4 font-[HelveticaNeueLTStd] max-sm:text-xs leading-4 text-left'>
        {item.title} In <b className='text-[#0073BB]'>{item.year}</b>
      </p>
    </div>
  ));
  return (
    <>
        <ProfileSwiper
            className="small-carousel"  
            slides={slides}
            animationType="SLIDE"
            animationDuration={1000}
            duration={4000}
            animationTimingFunction="linear"
        />
    </>
  );
} 
