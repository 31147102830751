import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

export default function DetailProfile({detail}) {
  return (
    <>
      <Swiper
        className="mySwiper h-full"
        pagination={true} modules={[Pagination]}
      >
        {detail?.data?.map((item, index) => {
          return (
            <SwiperSlide>
              <div key={index}>
                <img
                  className='w-full'
                  alt={`img${index + 1}`}
                  src={item.image}
                />
              </div>
            </SwiperSlide>)
        })}
      </Swiper>
    </>
  );
}

