import React, { useEffect, useState } from 'react';
import { ChevronRight, Stopwatch, X } from 'react-bootstrap-icons';
import WhyImportantImage from "../../assets/pages/whyimportant.png";
import LeadForm from '../common/LeadForm';

export default function WhyImportant({currentFlow, initialData}) {

  const [isSmall, setIsSmall] = useState(true);  
  const [isContentVisible, setIsContentVisible] = useState(false);
  
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  
  useEffect(() => {
    handleVisibility();
  }, [])
  
  
  const handleVisibility = () => {
    if (isSmall) {
      setIsSmall(false);
      setTimeout(() => {
        setIsContentVisible(true);
      }, 300); // Small delay before showing the content
    } else {
      setIsContentVisible(false);
      setTimeout(() => {
        setIsSmall(true);
      }, 300); // Delay to allow the content to fade out first
    }
  };
    
  return (
    <>
      <div className={`relative rounded-b-3xl my-12 max-sm:my-10 w-full`}>
        {currentFlow === "product" ? <p className='text-[#222] font-[TATAAIARegularPrint] text-[36px] max-sm:text-2xl mb-6 max-sm:mb-8 not-italic font-semibold leading-[39.6px]'>Why it is important to buy now!</p>
        :<p className='text-[#222] text-[36px] max-sm:text-2xl font-[TATAAIARegularPrint] mb-6 max-sm:mb-8 not-italic font-semibold leading-[39.6px]'>What are the benefits?</p>}
        <div className='flex'>
          {/* <div className='w-1/4 max-sm:w-[20%]'>
            <div className='bg-[#727272] rounded-2xl max-sm:rounded-l-none max-sm:rounded-r-full p-2 max-sm:p-1'>
              <div className='flex max-sm:block gap-4 rounded-2xl max-sm:rounded-full p-4 max-sm:p-4 bg-[#2e2c2c] font-semibold text-white'>
                <Stopwatch className='text-2xl max-sm:mx-auto max-sm:text-lg text-white'/><span className='max-sm:text-sm capitalize'>{initialData?.case_type}</span>
              </div>
            </div>
          </div> */}
          <div className='w-full max-sm:w-full max-sm:pl-4 max-sm:pr-[5%]'>
            <div className={`bg-[#E1DFE0] rounded-[25px] max-sm:rounded-lg w-full p-0 max-sm:p-5 transition-all duration-1000 ${false ? 'h-32 max-sm:h-28' : 'h-fit max-sm:h-fit'}`}>
              {/* <div className={`text-left  transition-all duration-1000 ease-out max-sm:mt-0 ${!isContentVisible ? "mt-8":"mt-0"}`}>
                <div className='flex justify-between'>
                  <p className='flex items-center gap-3 text-white text-2xl max-sm:text-lg not-italic font-semibold leading-[normal] tracking-[-1px]'>The sooner, the better!</p>
                  {!isSmall && <X onClick={handleVisibility} className='font-semibold text-white max-sm:text-xl text-[32px]' />}
                </div>
                <div onClick={handleVisibility} className='flex items-center justify-between'>
                  <p className='text-white text-sm max-sm:text-xs not-italic font-normal leading-[normal]'>As you get older, the premium amount will increase.</p>
                  {isSmall && <ChevronRight className='font-semibold text-white text-[18px]' />}
                </div>
              </div> */}
              <div className={`overflow-hidden transition-all mt-6 max-sm:mt-2 duration-1000 ease-out flex max-sm:grid text-left ${true ? 'max-h-fit opacity-100' : 'max-h-0 opacity-0'}`}>
                <div className='w-1/2 max-w-[500px] max-h-[350px] max-sm:w-full h-80 max-sm:h-48 flex bg-tansparent max-sm:mb-2 max-sm:order-1'>
                  <img src={initialData?.image} className='w-full max-sm:rounded-none rounded-l-[25px] h-full object-fill' width={"100%"} alt='important' />
                </div>
                <div className='w-1/2 flex flex-col max-sm:w-full space-y-4 max-sm:space-y-4 p-8 max-sm:p-0 max-sm:pt-2 max-sm:pr-0 max-sm:pl-2 max-sm:order-2'>
                  <div className='flex-1 space-y-4'>
                    <div className='flex justify-between'>
                      <p className='flex items-center gap-3 text-[#000] text-2xl max-sm:text-lg max-sm:text-center not-italic font-[TATAAIAPrint] font-semibold leading-[normal] tracking-[-1px]'>{ initialData?.title }</p>
                      {/* {!isSmall && <X onClick={handleVisibility} className='font-semibold text-white max-sm:text-xl text-[32px]' />} */}
                    </div>
                    {/* <div onClick={handleVisibility} className='flex items-center justify-between'>
                      <p className='text-white text-sm max-sm:text-xs not-italic font-normal leading-[normal]'>As you get older, the premium amount will increase.</p>
                      {isSmall && <ChevronRight className='font-semibold text-white text-[18px]' />}
                    </div> */}
                    <p className='text-[#000] font-[HelveticaNeueLTStd] text-sm max-sm:text-xs leading-5'>
                      {/* {initialData?.description.slice(0, 450) + (initialData?.description.length > 450 && "...")} */}
                      {initialData?.description?.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </p>
                  </div>
                  <button
                    type="button"
                    className="flex w-fit gap-2 bg-[#D62049] justify-center mt-auto max-sm:mx-auto items-center shrink-0 px-5 pt-1.5 pb-1 rounded-full"
                    onClick={()=>{setOpen(true)}}
                  >
                    <span className="text-[#fff] text-sm not-italic font-[TATAAIARegularPrint] leading-5">{currentFlow === "product" ? "Speak to me" : "Know more"}</span> <ChevronRight className='font-semibold text-white text-xs' />
                  </button>
                </div>  
              </div>
            </div>
          </div>
        </div>              
      </div>
      <LeadForm open={ open } handleClose={handleClose} formType="speak to me" source={"Know More"} />
    </>
  );
}
