import { useState } from 'react';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setSystemIp } from '../../redux/actions/micrositeAction';

function useMicroSiteEncrypt() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');
  const system_ip = useSelector((state) => state.microsite?.system_ip);
  
  const getRemoteUserIP = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      dispatch(setSystemIp(response.data.ip))
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address: ", error);
      return null;
    }
  };
  
  const formatDateToISD = () => {
    const date = new Date();

    // Adjust for Indian Standard Time (IST), which is UTC + 5:30
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    // Convert the date to the desired format
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

    // Rearrange the formatted date to 'YYYY-MM-DD HH:mm:ss'
    const [day, month, year] = formattedDate.split(', ')[0].split('/');
    const time = formattedDate.split(', ')[1];
    return `${year}-${month}-${day} ${time}`;
  }

  const microSiteEncrypt = async () => {
    try {
      const domain = window.location.hostname;
      const expiry = formatDateToISD();
      const ip_add = await getRemoteUserIP();
      const data = {
        domain: domain,
        expiry: expiry,
        ip_add: ip_add
      };
      console.log("data", data);
      const key = CryptoJS.enc.Utf8.parse('PWD123!@#XyZ$%^&'); // Must be 16 bytes for AES-128
      const iv = CryptoJS.enc.Utf8.parse('1aB2cD3eF4gH5iJ6'); // Must be 16 bytes for AES-CBC

      const jsonData = JSON.stringify(data);
      const encrypted = CryptoJS.AES.encrypt(jsonData, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });

      const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
      setToken(encryptedString);
      return encryptedString;
    } catch (err) {
      console.error('Encryption failed:', err);
      setError(err.message);
      return null;
    }
  };

  return { microSiteEncrypt, token, error };
}

export default useMicroSiteEncrypt;
