// VideoCarousel.jsx
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carousel.css'; // Custom CSS for styling
import CalcGif from "../../assets/carousel/shield.png";
import { ExclamationCircleFill, Whatsapp, X } from 'react-bootstrap-icons';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import Swipe from "../../assets/carousel/swipe_new.gif";
import CardCustom from '../section/CardCustom';
import RecaptchaComponent from '../common/RecaptchaComponent';
import useMicroSiteEncrypt from '../CustomHook/useMicroSiteEncrypt';
import { useDispatch, useSelector } from 'react-redux';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import "../common/common.css";
import { WhatsappIcon } from 'react-share';
import { submitLeadForm } from '../../redux/actions/micrositeAction';
import { isMobile } from 'react-device-detect';

const Carousel = ({ cardData, internalData }) => {  
  const sliderRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [message, setMessage] = useState("Hi, I would like to connect with you on Whatsapp to explore the right life insurance solution for me.");
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { microSiteEncrypt, token, error } = useMicroSiteEncrypt();
  const profile_data = useSelector((state) => state.microsite?.profile_data?.microsite);
  const current_flow = useSelector((state) => state.microsite?.home_data?.flow);
  const customize_fields = useSelector((state) => state.microsite?.profile_data?.customization);
  const url_data = useSelector((state) => state.microsite?.url_data);
  const [countryCode, setCountryCode] = useState('');

  const settings = {
    dots: false,
    dotsClass: "slick-dots",
    centerMode: cardData?.length > 2 ?  true : false,
    centerPadding: '0px',
    infinite: cardData?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: isMobile ? true : cardData?.length > 1,
    swipe: isMobile ? true : cardData?.length > 1,
    ...cardData.length > 1 && {initialSlide: 0} ,
    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices
        settings: {
          centerMode: true,
          centerPadding: '20px',
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    beforeChange: (current) => {
      console.log(`Current slide index: ${current}`);
      setActiveIndex(current);
      setTimeOver(false);
    },
  };
  
  const submitForm = () => {
        let payload = {
        fullname: name,
        phoneNo: countryCode+"-"+mobile?.replace(countryCode,""),
        recaptcha_token : recaptchaToken,
        url: url_data
        }
        
        const generateToken = async () => {
            const token = await microSiteEncrypt(payload);
            if (token && token !== "") {
                dispatch(submitLeadForm(token ,payload, "WhatsApp", current_flow, onSuccess))
            }
        };
        
        generateToken();
  }
  
  const handleCountryChange = (value) => {
    if (value) {
      try {
        const phoneNumberObj = parsePhoneNumber(value);
        const countryCode = '+' + phoneNumberObj?.countryCallingCode;
        setCountryCode(countryCode);
      } catch (error) {
        console.error('Invalid phone number', error);
      }
    } else {
      setCountryCode('');
    }
  };
  
  const onSuccess = () => {
    setOpen(false);
    setName("");
    setMobile("");
  }
  
  useEffect(() => {
    setTimeOver(true);
  }, [])
  
  return (
    <>
      <div className={`top-carousel ${ isMobile ? "primary-carousel" : (cardData?.length == 1 ? "one-card-carousel" : ( cardData?.length == 2 ? "secondary-carousel" : "primary-carousel"))}`}>
        <Slider ref={sliderRef} {...settings} className='w-full py-0 max-sm:py-0 m-auto'>
          {cardData?.map((card, index) => (
            <div className={`${isMobile && "mt-5"}`} key={index}>
              <div className="max-w-sm relative lg:w-[240px] xl:w-[280px] max-sm:w-[218px] mx-auto shadow-none rounded-[25px] bg-white">
                {index == 0 && <div className='absolute showSwipe w-[70px] pb-2 pt-1 rounded-l-full right-0 px-4 top-1/3 bg-transparent'><img className='' src={Swipe} alt='gif'/></div>}    
                <div className="w-full border-black rounded-[26px] border-2 lg:h-[22rem] xl:h-[26rem] max-sm:h-[21rem] my-12 max-sm:my-28 bg-cover bg-center">
                    <img className='rounded-[25px] h-full object-fill' src={ card } alt='card' />
                </div>
                <div className="bg-[#e1dfe0] p-2 opacity-80 rounded-tl-none rounded-bl-[35px] rounded-r-[40px] flex gap-2 items-center w-[80%] max-sm:w-[85%] absolute bottom-0">
                    <div className="rounded-full flex h-10 w-10 p-2 bg-white"><img src={CalcGif} className='rounded-md' alt="Solution" /></div>
                    <div className="">
                      {/* {internalData?.title != "" && <p className="text-black text-left text-[9px]">{ internalData?.title }</p>} */}
                      <button
                        type="button"
                        onClick={()=>{setOpen(true)}}
                        className="flex w-auto h-[30px] bg-[#D62049] justify-center items-center gap-2 shrink-0 border px-2 py-2 rounded-lg"
                      >
                        <Whatsapp className="text-white"/><span className="text-[#fff] text-[10px] max-sm:text-[9px] not-italic font-[TATAAIARegularPrint]">Let's discuss now</span>
                      </button>
                    </div>
                </div>
              </div>
            </div>
          ))}
          <div className={`relative ${isMobile && "mt-5"}`}>
            <CardCustom/>
          </div>  
        </Slider>
      </div>
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full max-sm:w-full sm:max-w-xl">
                  <div className="px-8 max-sm:px-4 py-4">
                  <X
                    className="absolute right-2 top-2 z-50 text-[#222] cursor-pointer text-2xl"
                      onClick={() => { setOpen(false); setMobileError(false); setNameError(false); setMobile(""); }}
                  />
                  <div className="flex justify-center m-0 p-0">
                    <WhatsappIcon className='rounded-full'/>
                  </div>
                  <div className="border-b pb-4 my-4">
                    <p className="text-[24px] max-sm:text-xl font-[TATAAIARegularPrint] text-center font-semibold">Let's Connect</p>
                  </div>
                  <form action="#" method="POST" className="my-4">
                    <div className="">
                      <div className="sm:col-span-3">
                        <div className="mt-1">
                          <textarea
                            name="message"
                            id="message"
                            rows={3}
                            className="block w-full resize-none font-[HelveticaNeueLTStd] rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#0073BB] sm:text-sm sm:leading-5"
                            value={message}
                            onChange={(e)=>{setMessage(e.target.value)}}
                          />
                        </div>
                      </div>    
                      <div className='flex max-sm:block mt-4 mb-8'>
                        <div className='w-1/2 max-sm:w-full max-sm:pr-0 pr-12'>
                          <div className="mt-1 relative">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className={`block w-full font-[HelveticaNeueLTStd] h-9 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${nameError != false ? "ring-red-300 focus:ring-red-300":"ring-gray-300 focus:ring-[#0073BB]"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-xs sm:leading-6`}
                              value={name}
                              placeholder={"Your name"+ (customize_fields?.whatsapp_contact?.name?.validation == "required" ? " *":"")}
                              onChange={(e) => {  
                                const regex = /^[a-zA-Z ]*$/;
                                if (regex.test(e.target.value)) {
                                  setName(e.target.value);
                                }  
                                if (e.target.value != "") {
                                  setNameError(false);
                                }
                              }}
                            />
                            {nameError != false && <ExclamationCircleFill title="Enter a name" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                          </div>
                        </div>
                        <div className='w-1/2 max-sm:mt-4 max-sm:w-full'>
                          <div className="mt-1 relative country-customize delay-form">
                            <PhoneInput
                              flags={flags}  
                              className='font-[HelveticaNeueLTStd]'                                
                              placeholder={"Your mobile number"+ (customize_fields?.whatsapp_contact?.name?.validation == "required" ? " *":"")}
                              value={mobile}
                              defaultCountry="IN"
                              countryCallingCodeEditable={false}
                              international={true}  
                              limitMaxLength  
                              onChange={(e) => {
                                handleCountryChange(e);  
                                if (e) {
                                  setMobile(e)
                                  if (isValidPhoneNumber(e)) {
                                      setMobileError(false); 
                                  } else {
                                      setMobileError(true); 
                                  }
                                } else {
                                  setMobile("");
                                  setMobileError(false); 
                                }
                              }}
                            />
                            {mobileError != false && <ExclamationCircleFill title="Enter a valid mobile number" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                          </div>
                        </div>
                      </div>
                      <RecaptchaComponent onVerify={setRecaptchaToken} />
                      <button
                        type="button"
                        className={`flex w-auto mx-auto bg-[#2b9452] justify-center items-center gap-2.5 shrink-0 border px-5 pb-1 pt-1.5 rounded-[30px] border-solid border-[#fff]`}
                        onClick={() => {
                            if (name == "" && customize_fields?.whatsapp_contact?.name?.validation == "required") {
                              setNameError(true);
                              return;
                            } else if ((mobile == "" || mobileError == true) && customize_fields?.whatsapp_contact?.phone?.validation == "required" ) {
                              setMobileError(true);
                              return;
                            } else {
                              setNameError(false);
                              setMobileError(false);
                            }
                            submitForm();
                            let anchor = document.createElement('a');
                            anchor.href = `https://api.whatsapp.com/send?phone=${profile_data?.contact}&text=${message}`;
                            anchor.target = '_blank';
                            document.body.appendChild(anchor);
                            anchor.click();
                          }}
                      >
                        <span className="text-[#fff] font-[TATAAIARegularPrint] text-sm not-italic leading-5">
                          Share message
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Carousel;