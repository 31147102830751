import React from "react";
// import StaticLogo from "../../assets/header/tata.png"
import CallButton from "../../assets/header/call.png"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Whatsapp } from "react-bootstrap-icons";
import ReactTicker from "./ReactTicker";

const Header = () => {
  const navigate = useNavigate();
  const profile_data = useSelector((state) => state.microsite?.profile_data?.microsite);
  const company_logo = useSelector((state) => state.microsite?.profile_data?.company_logo);
  return (
    <>
      <div className="fixed w-full z-[9] border-b border-[#d1d1d1] bg-white">
        <div className="px-4 max-sm:px-2 h-[70px] max-sm:h-[65px]">
          <div className="mx-auto my-2 max-sm:mt-4 max-sm:mb-0 flex items-center justify-between px-2 sm:px-4 lg:max-w-7xl">
            <div onClick={()=>{navigate("/")}} className="flex items-center gap-2 sm:gap-4">
              <img src={company_logo} className="w-24 max-sm:w-20" alt="logo" />
            </div>
            <div className="flex items-center gap-4 sm:gap-8">
              <p className="text-[#282727] font-[TATAAIARegularPrint] font-bold max-sm:text-xs">Let's Connect</p>
              <a target="blank" className="gradient-shadow-header border border-gray-200 rounded-full p-2" href={`tel:${profile_data?.contact}`}>
                <img src={CallButton} alt="call"/>
              </a>
              <a target="blank" className="gradient-shadow-header border border-gray-200 rounded-full p-2" href={`https://api.whatsapp.com/send?phone=${profile_data?.contact}&text=${"Hi, I would like to connect with you on Whatsapp to explore the right life insurance solution for me."}`}>
                <Whatsapp className="h-6 w-6 text-zinc-500 outline-blue-50 hover:text-zinc-500" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed top-[4.8rem] font-[TATAAIARegularPrint] w-full z-10" >
        <ReactTicker/>
      </div>
    </>
  );
};

export default Header;
