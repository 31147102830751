import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailProfile from '../../section/DetailProfile';
import DetailCircle from '../../section/DetailCircle';
import useMicroSiteEncrypt from '../../CustomHook/useMicroSiteEncrypt';
import { fetchLeaderDetailData } from '../../../redux/actions/micrositeAction';

const SidhiContainer = (props) => {
    const dispatch = useDispatch();
    const { microSiteEncrypt } = useMicroSiteEncrypt();
    const url_data = useSelector((state) => state.microsite?.url_data);
    const profile_data = useSelector((state) => state.microsite?.profile_data);


    useEffect(() => {
        const startTracking = async () => {
            const tokenLeader = await microSiteEncrypt();
            if (tokenLeader && tokenLeader !== "") {
                dispatch(fetchLeaderDetailData(tokenLeader, url_data));
            }

            document.title = `Microsite: ${profile_data?.microsite?.name} | TATA AIA Partner`;
        };

        startTracking();
    }, []);
    

    return (
            <div>
                <div className='h-[70vh] max-sm:h-[25vh]'>
                    <DetailProfile detail={profile_data?.profile_images} />
                </div>
                <div className='max-sm:px-3 bg-[#0073BB]'>
                    <div className='lg:max-w-7xl mx-auto relative flex justify-center items-center pb-2 pt-2 max-sm:pt-2 max-sm:pb-1 '>
                        <div className='flex items-center max-sm:justify-between gap-12 max-sm:gap-5'>
                            <div>
                                <p className='text-[#fff] text-3xl max-sm:text-[18px] mb-1 font-[TATAAIARegularPrint] not-italic capitalize font-bold leading-[normal]'>{profile_data?.microsite?.name}</p>
                                <p className='text-[#fff] text-lg max-sm:text-sm not-italic font-[TATAAIAPrint] capitalize font-medium leading-[130%]'>{profile_data?.microsite?.designation}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-white max-sm:space-y-8 py-4 max-sm:pt-4 lg:max-w-7xl mx-auto'>
                    <div className='w-[80%] max-sm:w-full flex justify-center mx-auto'>
                        <DetailCircle profileData={profile_data?.partner_achivements?.data} />
                    </div>
                </div>
            </div>
    );
};

export default SidhiContainer;
