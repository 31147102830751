import React from 'react';
import CertficateImage from "../../assets/carousel/certi_img.png";
import TrainingCarousel from '../carousel/TrainingCarousel';

export default function Certification({certificateData}) {
  return (
    <>
          <TrainingCarousel cardData={ certificateData } />
    </>
  );
} 
