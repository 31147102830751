import React from "react";
import { Envelope, Facebook, GeoAltFill, Linkedin, TelephoneOutboundFill, TwitterX, Whatsapp } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import PDF from "../../assets/pages/disclaimer.pdf"
export default function Footer() {
  
  const profile_data = useSelector((state) => state.microsite?.profile_data?.microsite);
  
  const handleRedirect = (address) => {
    if (address.trim()) {
      const encodedAddress = encodeURIComponent(address);
      const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };
  
  const handleWhatsappRedirect = (phoneNumber) => {
    if (phoneNumber.trim()) {
      const encodedMessage = encodeURIComponent("");
      const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };
  
  const handleDownload = () => {
    window.open(PDF, '_blank');
  };
  
  const handleEmailRedirect = (email) => {
    if (email.trim()) {
      const encodedSubject = encodeURIComponent("");
      const encodedMessage = encodeURIComponent("");
      const mailtoUrl = `mailto:${email}?subject=${encodedSubject}&body=${encodedMessage}`;
      const newWindow = window.open(mailtoUrl, '_blank');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };
  
  const handleCallRedirect = (phoneNumber) => {
    if (phoneNumber.trim()) {
      const telUrl = `tel:${phoneNumber}`;
      window.location.href = telUrl;
    }
  };
  
  return (
    <footer className="bg-[#E1DFE0]" aria-labelledby="footer-heading">
      <div className="py-6 px-8 max-sm:px-4 mx-auto lg:max-w-7xl">
        <div className="border-t border-white/10 lg:flex lg:items-center lg:justify-between">
          <div className="space-y-5">
            <h3 className="text-[32px] max-sm:text-2xl font-[TATAAIARegularPrint] max-sm:mb-6 text-left max-sm:text-center text-[#444] not-italic font-bold leading-[35px]">You can <br className="hidden"/> also reach me at:</h3>
            <div className="max-sm:hidden">
              <div className="flex items-center gap-3">
                <GeoAltFill className="text-2xl text-[#282727]"/>
                <p className="text-[#282727] text-xs not-italic font-[HelveticaNeueLTStd] font-normal leading-[normal]">
                  {profile_data?.address}
                </p>
              </div>
              <button
                type="button"
                onClick={()=>{handleRedirect(profile_data?.address)}}
                className="flex w-auto mt-5 bg-[#D62049] justify-center items-center gap-2.5 shrink-0 border px-5 pt-1.5 pb-1 rounded-[30px]"
              >
                <span className="text-[#fff] font-[TATAAIARegularPrint] text-sm not-italic leading-5">Get direction to branch office</span>
              </button>
            </div>
          </div>
          <div className="flex gap-4 max-sm:gap-1 justify-between">
            <div onClick={()=>{handleCallRedirect(profile_data?.contact)}} className="w-[117.167px] h-[117.167px] p-2 grid justify-center items-center bg-[#A7A9AC] cursor-pointer rounded-[10px]">
              <TelephoneOutboundFill className="m-auto text-[#000] text-3xl" />
              <p className="text-[#000] text-center text-xs  max-sm:text-[11px] not-italic font-[HelveticaNeueLTStd] font-semibold leading-[normal]">+91-{ profile_data?.contact }</p>
            </div>
            <div onClick={()=>{handleWhatsappRedirect(profile_data?.contact)}} className="w-[117.167px] h-[117.167px] p-2 grid justify-center items-center bg-[#A7A9AC] cursor-pointer rounded-[10px]">
              <Whatsapp className="m-auto text-3xl text-[#000]" />
              <p className="text-[#000] text-center text-xs max-sm:text-[11px] font-[HelveticaNeueLTStd] not-italic font-semibold leading-[normal]">+91-{ profile_data?.contact }</p>
            </div>
            <div onClick={()=>{handleEmailRedirect(profile_data?.email)}} className="w-[117.167px] h-[117.167px] p-2 grid justify-center items-center bg-[#A7A9AC] cursor-pointer rounded-[10px]">
              <Envelope  className="m-auto text-3xl text-[#000]"/>
              <p style={{ wordBreak: "break-word" }} className="text-[#000] font-[HelveticaNeueLTStd] max-sm:text-[11px] text-center text-xs not-italic font-semibold leading-[normal]">{ profile_data?.email }</p>
            </div>
          </div>
          <div className="max-sm:block hidden items-center max-sm:justify-between my-6 gap-3">
              <div className="flex justify-center items-center gap-3">
                <GeoAltFill className="text-2xl text-[#282727]"/>
                <p className="text-[#282727] font-[HelveticaNeueLTStd] text-[16px] max-sm:text-xs not-italic max-sm:text-left font-[400] capitalize leading-[normal]">
                  {profile_data?.address}
                </p>
              </div>
              <button
                type="button"
                onClick={()=>{handleRedirect(profile_data?.address)}}
                className="flex w-auto justify-center items-center mt-4 mx-auto gap-2.5 shrink-0 border px-5 py-1.5 pb-1 rounded-[30px] bg-[#D62049]"
              >
                <span className="text-[#fff] font-[TATAAIARegularPrint] text-sm not-italic font-bold leading-5">Get direction to branch office</span>
              </button>
            </div>
        </div>
        { (profile_data?.facebook_page != "" && profile_data?.linkedin_page != "") && <>
        <hr className="mt-4 bg-[#cbd1da] h-[2px]"/>
        <div className="border-t space-y-2  border-white/10 md:grid md:items-center justify-center">
          <p className="mt-2 text-[14px] text-[#444] font-semibold font-[TATAAIAPrint] leading-6">Stay connected at:</p>
          <div className="flex space-x-6 justify-center">
            {profile_data?.facebook_page != "" && <a target="blank" href={profile_data?.facebook_page} className="text-[#1877F2]">
              <Facebook className="h-6 w-6" aria-hidden="true" />
            </a>}
            {profile_data?.linkedin_page != "" && <a target="blank" href={profile_data?.linkedin_page} className="text-[#0077B5]">
              <Linkedin className="h-6 w-6" aria-hidden="true" />
            </a>}
            {/* {profile_data?.contact != "" && <a target="blank" href={`https://wa.me/${profile_data?.contact}`} className="text-[#25D366]">
              <Whatsapp className="h-6 w-6" aria-hidden="true" />
            </a>} */}
          </div>
        </div>
        </>}
        <p onClick={handleDownload} className="text-[#0073BB] hover:underline mt-4 max-sm:text-center text-left cursor-pointer text-xs  max-sm:text-[11px] not-italic font-[HelveticaNeueLTStd] font-semibold leading-[normal]">Disclaimer</p>
      </div>
    </footer>
  )
}