import React, { Fragment, useRef, useState } from 'react'
import { saveAs } from 'file-saver';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'

export default function Stories({ourStory}) {
  const cancelButtonRef = useRef();
  const [showPreviewer, setShowPreviewer] = useState(false);
  
  const handleDownload = (pdfNameData, pdfUrlData) => {
    saveAs(pdfUrlData, pdfNameData);
  };
    
  return (
    <>
      <div className={`bg-[#e0ecf4] lg:max-w-7xl mx-auto relative rounded-3xl my-8 text-left max-sm:text-center justify-between flex max-sm:block w-full transition-all duration-500`}>
            <div className='mt-8 max-sm:mt-0 w-[40%] max-sm:w-full px-11 max-sm:px-4 max-sm:py-8 space-y-4 max-sm:order-2'>
              {/* <p className='text-[#222] w-[60%] max-sm:w-full text-[20px] not-italic font-normal leading-[130%]'>Being an advisor not just helped me</p>
              <p className='text-[#626262] text-[32px] max-sm:text-2xl not-italic font-bold leading-[35px]'>Be an <b className='text-[#222] font-semibold'>entrepreneur</b> but also increased my <b className='text-[#222] font-semibold'>earning</b> potential</p> */}
              <p className='text-[#222] w-full max-sm:w-full text-[20px] not-italic font-semibold font-[TATAAIAPrint] leading-[130%]'>{ourStory?.title}</p>
              <p className='text-[#626262] text-[12px] max-sm:text-xs not-italic font-semibold font-[HelveticaNeueLTStd] leading-2'>{ ourStory?.description?.slice(0,600) + (ourStory?.description?.length > 600 ? "...":"") }</p>
            </div>
            <div className='w-[60%] max-sm:w-full space-y-4 max-sm:order-1'>
              <img className='rounded-3xl w-full' src={ourStory?.thumb_image} alt="group"/>
            </div>
      </div>
      <button
        type="button"
        onClick={()=>{handleDownload(ourStory?.title, ourStory?.content_file)}}
        className="flex w-auto m-auto bg-[#D62049] justify-center items-center gap-2.5 shrink-0 border px-5 py-1.5 pb-1 rounded-[30px]"
      >
        <span className="text-[#fff] font-[TATAAIARegularPrint] text-sm not-italic font-bold leading-5">{"Read our stories"}</span>
      </button>
      
      <Transition show={showPreviewer} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>{setShowPreviewer(false)}}>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-[#323639] px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl max-sm:w-full sm:py-1 sm:px-1">
                    <div>
                      {ourStory?.content_file ? (
                        <iframe src={`https://docs.google.com/viewer?url=${ourStory?.content_file}&embedded=true`} width="100%" height="500px" />
                      ) : (
                        <p>No document to preview</p>
                      )}
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
      </Transition>
      
    </>
  )
}
