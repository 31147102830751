// VideoCarousel.jsx
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carousel.css'; // Custom CSS for styling
import { Pause, Play, PlayBtn, Triangle, TriangleFill } from 'react-bootstrap-icons';

const VideoSwiper = ({ videos }) => {
  
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  
  const settings = {
    customPaging: function(i) {
      return (
        <span className='relative'>
          <span className='cursor-pointer away'>
            <img className='rounded-2xl max-sm:rounded-xl' style={{height:"60px", width:"70px"}} src={videos[i].thumb_image} alt={`thumbnail-${i}`} />
            <TriangleFill className='absolute text-[#cbc9c9] z-0 -translate-x-2/4 left-0 -top-2.5' />
            <span className='count absolute top-[50%] -translate-x-2/4 -translate-y-2/4 text-white font-bold'>{ i+1 }</span>
          </span>
        </span>
      );
    },
    dots: true,
    dotsClass: "slick-dots custom-dots",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handlePlayPause = (index) => {
    let a = document.getElementById(`video-${index}`);
    if (isPlaying) {
      videoRef?.current?.pause();
      a.pause();
    } else {
      videoRef?.current?.play();
      a.play();
    }
    setIsPlaying(!isPlaying);
  };
  
  const handleSlideChange = (oldIndex, newIndex) => {
    let a = document.getElementById(`video-${newIndex}`);
    let b = document.getElementById(`video-${oldIndex}`);
    a.play();
    b.pause();
    setIsPlaying(true);
  };
  
  return (
    <div className="carousel-container video-carousel w-[90%] max-sm:w-full max-sm:ml-0 max-sm:mr-auto mx-auto max-sm:my-8 my-12">
      <Slider {...settings} className='w-3/4 max-sm:w-full m-auto' beforeChange={handleSlideChange}>
        {videos?.map((video, index) => (
          <div key={index} className="video-slide">
            <div className='flex items-center h-12 gap-4 px-8'>
              {/* <span className='text-gray-600 text-xl'>0{index + 1}</span> */}
              <h1 className='text-gray-200 font-[TATAAIARegularPrint] font-semibold text-xl max-sm:text-sm text-left'>{ video?.title }</h1></div>
            <video id={`video-${index}`} ref={videoRef} autoplay loop controls className='rounded-[20px]'>
              <source src={video?.content_file} type="video/mp4" />
            </video>
            <button className="play-pause-btn bg-white" onClick={() => { handlePlayPause(index) }}>
              {isPlaying ? <Pause/> : <Play/>}
            </button>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VideoSwiper;
