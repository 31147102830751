import React, { useState } from 'react';
import "./section.css";
import AdvisorImg from "../../assets/pages/advisor.png"
import LeadForm from '../common/LeadForm';

const JoinAdvisor = ({ title, subTitle, buttonTxt }) => {
  
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  
  return (
    <>
      <div className='flex justify-center text-left py-4 mx-auto lg:max-w-7xl'>    
          <div className="speak-to-me py-6 flex items-center gap-12 max-sm:gap-4 px-12 max-sm:px-4 rounded-[10px] shadow-[0_8px_16px_0_#E1DFE0] bg-[#e1dfe0]">
            <div className='bg-white h-fit rounded-full'><img src={AdvisorImg} className='w-[120px] max-sm:w-40' alt="emote" /></div>
            <div className='max-sm:text-left'>
            <p className='text-[#0073BB] text-2xl font-[TATAAIARegularPrint] max-sm:text-xl mb-2 not-italic font-bold leading-[normal] max-sm:leading-6'>{ title }</p>
            <p className='text-[#444] mb-6 max-sm:mb-2 font-[TATAAIAPrint] text-md max-sm:text-xs not-italic text-left font-normal leading-[120%]'>{ subTitle }</p>
              <button
                type="button"
                className="flex w-auto bg-[#D62049] font-[TATAAIARegularPrint] justify-center items-center gap-2.5 shrink-0 border px-5 py-1.5 pb-1 rounded-[30px]"
              >
                <span className="text-[#fff] text-[11px] not-italic font-bold leading-5 uppercase" onClick={()=>{setOpen(true)}}>{buttonTxt}</span>
              </button>
            </div>          
          </div>
      </div>
      <LeadForm open={ open } handleClose={handleClose} formType="join advisor" source={"Join As Financial Advisor"} />
    </>
  );
};

export default JoinAdvisor;
