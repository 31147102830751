import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ChevronRight, ArrowRight, X, ChevronDown } from 'react-bootstrap-icons';
import LeadForm from '../common/LeadForm';

export default function SolutionTypes(props) {
  const [activeCard, setActiveCard] = useState(null);
  const [activeData, setActiveData] = useState(null);
  const productRef = useRef(null); // Reference for the p tag
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  
  const handleCardClick = (index, data) => {
    setActiveCard(index === activeCard ? null : index);
    setActiveData(data);
  };

  return (
    <>
      <div className="relative  solution-type rounded-b-3xl py-12 max-sm:py-4 w-full transition-all duration-500">
        <p className="text-[#222] text-[36px] max-sm:text-2xl font-[TATAAIARegularPrint] mb-4 max-sm:mb-0 not-italic font-bold leading-[35px]">Take a look at our solutions</p>
        <p className="text-[#222] text-[36px] font-[TATAAIARegularPrint] max-sm:text-2xl not-italic font-bold leading-[35px]">just for you!</p>
        <div className="relative max-sm:hidden flex flex-wrap gap-0 mt-12 card-container">
          {props.solutionData?.map((_, index) => (
            <div
              key={index}
              className={`relative transition-all duration-500 w-[22%] max-sm:w-1/2 ease-in-out ${activeCard === index ? 'z-10' : ''}`}
            >
              <div
                onClick={() => handleCardClick(index, _)}
                className={`${activeCard !== index ? "bg-[#E1DFE0]":"bg-[#aebfc9]"} rounded-[10px] cursor-pointer card transform transition-transform duration-500 ease-in-out ${activeCard === index ? 'scale-x-[1.7] scale-y-[1.3] p-4' : 'scale-100 p-6'}`}
                style={{
                  position: activeCard === index ? 'absolute' : 'relative',
                  inset: activeCard === index ? "12px" : 'auto',
                  width: activeCard === index ? 'calc(100% - 2rem)' : '100%',
                  height: activeCard === index ? 'auto' : '100%',
                  zIndex: activeCard === index ? 20 : 1,
                }}
              >
                {activeCard !== index ? (
                  <div className="transition-opacity duration-500 ease-in-out opacity-100">
                    <div className="w-full flex justify-end mb-6"><div className='w-10 h-10'><img src={_?.icon} className='w-full h-full object-contain' alt="im" /></div></div>
                    <div className="text-left space-y-4">
                      <p className="flex items-center gap-3 font-[TATAAIARegularPrint]  text-[#444] text-xl not-italic font-semibold leading-[normal]">
                        {_?.title} <ChevronRight className="font-semibold mt-1 text-[18px]" />
                      </p>
                      <p className="text-[#444] font-[HelveticaNeueLTStd] text-sm not-italic font-normal leading-[normal]">{_?.description || "Secure your financial future from today itself"}</p>
                    </div>
                  </div>
                ) : (
                  <div className="transition-opacity duration-500 ease-in-out opacity-100">
                    <X className="absolute right-2 top-2 text-[#282727] text-2xl" onClick={() => setActiveCard(null)} />
                    <div className="w-full flex items-center justify-between mb-2">
                      <div>
                        <p className="text-left font-[TATAAIARegularPrint] w-full text-[#0073BB] text-base not-italic font-semibold leading-[normal]">
                          {_?.title}
                        </p>
                        <p className="text-[#444] font-[HelveticaNeueLTStd] w-[95%] text-left text-[9px] not-italic font-medium leading-[normal]">
                          {_?.description || "Secure your financial future from today itself"}
                        </p>
                      </div>
                      <div className="m-auto">
                        <div className='w-12 h-12 pr-3'>
                          <img src={_?.icon} className='w-full h-full object-contain' alt="im" />
                        </div>
                      </div>
                    </div>
                    <div className="text-left space-y-2">
                      <p className="text-[#444] text-[8px] font-[HelveticaNeueLTStd] not-italic font-normal leading-[normal]">Top picks for you</p>
                      <div className="space-y-2">
                        <ul className="text-black list-disc pl-3 space-y-1 truncate overflow-hidden whitespace-nowrap">
                            {_?.case_data?.map((solution, s_ind) => {
                              return (
                                <li title={solution?.title} className="text-[9px] font-[TATAAIAPrint] font-semibold text-[#000]">
                                  {solution?.title?.slice(0,27)}{solution?.title?.length > 27 ? "...":""}
                                </li>
                              );
                            }) }
                        </ul>
                        <div  onClick={() => { setOpen(true) }} className="w-fit flex items-center gap-2 text-left px-1 pt-1 pb-1 bg-[#D62049] rounded-sm">
                          <p className="text-[10px] text-[#fff] font-[TATAAIARegularPrint]  font-normal leading-3">Connect with me today!</p>
                          <ArrowRight className="text-sm text-[#fff]" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="relative max-sm:flex hidden flex-col mt-6">
          {props.solutionData?.map((_, index) => (
            <div key={index} className={`relative transition-all duration-1000 ease-in-out w-full p-1`}>
              <div
                style={{maxHeight: activeCard === index ? 500 : 80 }}
                className={`rounded-[10px] relative cursor-pointer transform px-6 py-3 transition-all duration-500 ease-in-out w-full ${activeCard === index ? 'bg-[#aebfc9]' : 'bg-[#e1dfe0]'} overflow-hidden`}
              >
                {activeCard !== index && <div onClick={() => handleCardClick(index, _)} className="transition-opacity duration-500 ease-in-out opacity-100 flex items-center gap-5">
                  <div className='w-12 h-12'>
                    <img src={_?.icon} className='w-full h-full object-contain' alt="im" />
                  </div>
                  <div className="text-left w-2/3 space-y-0">
                    <p className="gap-3 font-[TATAAIARegularPrint] text-[#282727] text-lg not-italic font-semibold leading-[normal]">
                      {_?.title}
                    </p>
                    <p className="text-[#444] font-[HelveticaNeueLTStd] text-sm not-italic font-medium leading-[normal]">
                      {_?.description || "Secure your financial future from today itself"}
                    </p>
                  </div>
                  <ChevronDown className="font-semibold text-[#282727] text-[18px]" />
                </div>}

                {activeCard === index && (
                  <div ref={productRef}>
                    <X className="absolute right-2 top-2 text-[#444] text-2xl cursor-pointer" onClick={() => setActiveCard(null)} />
                    <div className="w-full flex items-center justify-between mb-2">
                      <div>
                        <p className="text-left font-[TATAAIARegularPrint] text-[#282727] text-3xl not-italic font-semibold leading-[normal]">
                          {_?.title}
                        </p>
                        <p className="text-[#444] font-[HelveticaNeueLTStd] w-[80%] text-left text-sm not-italic font-medium leading-[normal]">
                          {_?.description || "Secure your financial future from today itself"}
                        </p>
                      </div>
                      <div className="m-auto">
                        <div className='w-20 h-20 pr-3'>
                          <img src={_?.icon} className='w-full h-full object-contain' alt="im" />
                        </div>
                      </div>
                    </div>
                    <div className="text-left space-y-2">
                      <p className="text-[#444] font-[HelveticaNeueLTStd] text-sm not-italic font-normal leading-[normal]">Top picks for you:</p>
                      <div className='space-y-3'>
                        <ul className="w-full list-disc pl-4 space-y-1">
                          {_?.case_data?.map((solution, s_ind) => (
                            <li key={s_ind}>
                              <p title={solution?.title} className="text-[16px] font-[TATAAIAPrint] font-semibold text-[#444]">
                                {solution?.title}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div onClick={() => { setOpen(true) }} className="w-fit px-3 py-1.5 pt-2 flex rounded-lg items-center text-left gap-2 bg-[#D62049]">
                          <p className="text-sm text-[#fff] font-[TATAAIARegularPrint] font-semibold leading-5">Connect with me today!</p>
                          <ArrowRight className="text-xl text-[#fff]" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <LeadForm open={open} handleClose={handleClose} formType="speak to me" source={"Speak with me"} />
    </>
  );
}
