import React, { useState } from "react";
import "../section/section.css";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { ExclamationCircleFill, X } from "react-bootstrap-icons";
// import GifImage from "../../assets/pages/emoji3.png"
import GifImage from "../../assets/modal/neeraj.png"
import { useDispatch, useSelector } from "react-redux";
import { submitLeadForm } from "../../redux/actions/micrositeAction";
import RecaptchaComponent from "./RecaptchaComponent";
import useMicroSiteEncrypt from "../CustomHook/useMicroSiteEncrypt";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import "./common.css";

export default function DelayForm(props) {
  
  const dispatch = useDispatch();
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { microSiteEncrypt } = useMicroSiteEncrypt();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [checkFlag, setCheckFlag] = useState(true);
  const [countryCode, setCountryCode] = useState('');
  const [message, setMessage] = useState("");
  const isLoading = useSelector((state) => state.microsite?.isLoading);
  const url_data = useSelector((state) => state.microsite?.url_data);
  const current_flow = useSelector((state) => state.microsite?.home_data?.flow);
  const customize_fields = useSelector((state) => state.microsite?.profile_data?.customization);
  
  const submitForm = () => {
    if (name == "" && customize_fields?.leadPopup_contact?.name?.validation == "required") {
      setNameError(true);
      return;
    } else if ((mobile == "" || mobileError == true) && customize_fields?.leadPopup_contact?.phone?.validation == "required" ) {
      setMobileError(true);
      return;
    } else if ((email == "" && customize_fields?.leadPopup_contact?.email?.validation == "required") || emailError == true) {
      setEmailError(true);
      return;
    } else {
      setNameError(false);
      setEmailError(false);
      setMobileError(false);
    }
    
    let payload = {
      fullname: name,
      phoneNo: countryCode+"-"+mobile?.replace(countryCode,""),
      emailId: email,
      recaptcha_token : recaptchaToken,
      url: url_data
    }
    
    const generateToken = async () => {
      const token = await microSiteEncrypt(payload);
      if (token && token !== "") {
        dispatch(submitLeadForm(token ,payload, "Lead Popup", current_flow, onSuccess))
      }
    };
    
    generateToken();
  }
  
  const handleCountryChange = (value) => {
    if (value) {
      try {
        const phoneNumberObj = parsePhoneNumber(value);
        const countryCode = '+' + phoneNumberObj?.countryCallingCode;
        setCountryCode(countryCode);
      } catch (error) {
        console.error('Invalid phone number', error);
      }
    } else {
      setCountryCode('');
    }
  };
  
  const destroyAllError = () => {
    setName("");
    setEmail("");
    setMobile("");
    setCheckFlag(false);
  }
  
  const onSuccess = () => {
    setName("");
    setEmail("");
    setMobile("");
    setCheckFlag(false);
    props.handleClose();
  }
  
  return (
    <Transition show={props.open}>
      <Dialog className="relative z-10" onClose={props.handleClose}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-[#e1dfe0] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <div className="px-8 py-4">
                  <X
                    className="absolute right-2 top-2 z-50 text-[#222] cursor-pointer text-2xl"
                    onClick={() => {props.handleClose(); destroyAllError();}}
                  />
                  <div className="flex justify-center mb-4 p-0">
                    <img src={GifImage} className="w-[300px] max-sm:w-full" alt=""/>
                  </div>
                  <div className="border-b border-[#bababa] pb-0">
                    <p className="text-[24px] font-[TATAAIARegularPrint] max-sm:text-xl text-[#0073BB] font-semibold">You have made the right choice.</p>
                    <p className="text-sm font-[TATAAIAPrint]">
                    Let’s connect!
                    </p>
                  </div>
                  <form action="#" method="POST" className="my-4 mt-2">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-3 sm:grid-cols-3">
                      <div>
                        <div className="mt-1 relative">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className={`block w-full h-9 font-[HelveticaNeueLTStd] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${nameError != false ? "ring-red-300 focus:ring-red-300":"ring-gray-300 focus:ring-[#0073BB]"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-xs sm:leading-6`}
                            value={name}
                            placeholder={"Your name"+ (customize_fields?.leadPopup_contact?.name?.validation == "required" ? " *":"")}
                            onChange={(e) => { 
                              const regex = /^[a-zA-Z ]*$/;
                              if (regex.test(e.target.value)) {
                                setName(e.target.value);
                              }                          
                              if (e.target.value != "") {
                                setNameError(false);
                              }
                            }}
                          />
                          {nameError != false && <ExclamationCircleFill title="Enter a name" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                        </div>
                      </div>
                      <div>
                        <div className="mt-1 relative country-customize delay-form">
                          <PhoneInput
                            flags={flags}   
                            className="font-[HelveticaNeueLTStd]"                            
                            placeholder={"Your mobile number"+ (customize_fields?.leadPopup_contact?.name?.validation == "required" && " *")}
                            value={mobile}
                            countryCallingCodeEditable={false}
                            international={true}
                            defaultCountry="IN"
                            limitMaxLength  
                            onChange={(e) => {
                              handleCountryChange(e);
                              if (e) {
                                setMobile(e)
                                if (isValidPhoneNumber(e)) {
                                    setMobileError(false); 
                                } else {
                                    setMobileError(true); 
                                }
                              } else {
                                setMobile("");
                                setMobileError(false); 
                              }
                            }}
                          />
                          {mobileError != false && <ExclamationCircleFill title="Enter a valid mobile number" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                        </div>
                      </div>
                      <div>
                        <div className="mt-1 relative">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder={"Your email" + (customize_fields?.leadPopup_contact?.email?.validation == "required" ? " *":"")}
                            className={`block w-full h-9 rounded-md font-[HelveticaNeueLTStd] border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${emailError != false ? "ring-red-300 focus:ring-red-300":"ring-gray-300 focus:ring-[#0073BB]"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-xs sm:leading-6`}
                            value={email}
                            onChange={(e) => {
                              const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;                       
                              if (regex.test(e.target.value) || e.target.value == "") {
                                setEmailError(false);
                              } else {
                                setEmailError(true);
                              }   
                              setEmail(e.target.value)
                            }}
                          />
                          {emailError != false && <ExclamationCircleFill title="Enter a valid email" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                        </div>
                      </div>
                     
                      <div className="sm:col-span-3">
                        <div className="mt-1">
                          <textarea
                            name="message"
                            id="message"
                            placeholder={"Message"}
                            rows={3}
                            className="block w-full font-[HelveticaNeueLTStd] resize-none rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#0073BB] sm:text-xs sm:leading-6"
                            value={message}
                            onChange={(e)=>{setMessage(e.target.value)}}
                          />
                        </div>
                      </div>
                      
                      <div className="sm:col-span-3 relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="check"
                            aria-describedby="comments-description"
                            name="check"
                            type="checkbox"
                            className="h-4 w-4 rounded border-[#a7aaaf] text-[#0073BB] focus:ring-[#0073BB]"
                            checked={checkFlag}
                            onChange={(e) => {
                              setCheckFlag(e.target.checked)
                            }}
                          />
                        </div>
                        <div className="ml-3 w-full text-sm leading-1">
                          <span
                            id="comments-description"
                            className="text-[#444] font-[HelveticaNeueLTStd] text-[12px] not-italic font-normal"
                          >
                            TATA AIA Life Insurance Co. Ltd will send you
                            updates on your policy, new products and services,
                            insurance solutions or related information.
                          </span>
                        </div>
                      </div>
                      <RecaptchaComponent onVerify={setRecaptchaToken} />
                      <button
                        type="button"
                        className={`flex  ${isLoading != false ? "w-20 h-[28px]":"w-fit"} h-[30px] ${ checkFlag !== false ? "bg-[#D62049]":"bg-[#dc5c7a]"} font-[TATAAIARegularPrint] justify-center max-sm:m-auto items-center gap-2.5 shrink-0 border px-5 pb-1.5 pt-2 rounded-[30px]`}
                        onClick={() => {
                          console.log("recaptchaToken", recaptchaToken);
                          if (checkFlag != false) {
                            submitForm();
                          }
                        }}
                        disabled={checkFlag != true}
                      >
                        {isLoading == true ? <svg aria-hidden="true" className="w-4 h-4 text-[#D62049] animate-spin dark:text-[#0073BB] fill-[#fff]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        :
                        <span className="text-[#fff] text-sm not-italic leading-5">
                          Submit
                        </span>}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
