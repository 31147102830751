import React, { useState, useRef, useEffect } from 'react';
import { ArrowDown, ArrowRight, ChevronRight, ShareFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import SmallCarousel from './SmallCarousel';
import LeadForm from '../common/LeadForm';
import ShareModal from './ShareModal';
import { isMobile } from 'react-device-detect';

export default function RecruitmentProfile({profileData}) {
  const navigate = useNavigate();
  const location = useLocation();
  const nameRef = useRef(null); // Reference for the p tag
  const [isSmall, setIsSmall] = useState(true);
  const [isShow, setIsShow] = useState(true);
  const [open, setOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const descriptionRef = useRef(null);
  const contentRef = useRef(null); // Ref for the content area to animate height

  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
    setModalIsOpen(false);
  }
  
  const toggleSize = () => {
    if (isSmall == true) {
      setIsSmall(false);
      setTimeout(() => {
        setIsContentVisible(true);
        setIsShow(false);
      }, 300); // Small delay before showing the content
    } else {
      setIsShow(true);
      setIsContentVisible(false);
      setTimeout(() => {
        setIsSmall(true); 
      }, 300); // Delay to allow the content to slide out first
    }
  };
  
  const handleSmallHeight = () => {
    if (isMobile) {
      if (isSmall) {
        return 130 + nameRef?.current?.offsetHeight
      } else {
        return 480 + nameRef?.current?.offsetHeight
      }
    } else {
      if (isSmall) {
        return 220
      } else {
        return 530
      }
    }
  }

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = isMobile ? "80px":"50px";
      if (isSmall) {
        descriptionRef.current.style.height = '3.9em'; // Adjust based on the number of visible lines in desc1
      }
    }
  }, [isSmall]);

  useEffect(() => {
    if (contentRef.current) {
      if (isSmall) {
        contentRef.current.style.height = '0'; // Slide content area out when collapsing
      } else {
        contentRef.current.style.height = `${contentRef.current.scrollHeight}px`; // Expand content area
      }
    }
  }, [isSmall]);

  return (
    <div style={{height: handleSmallHeight()}} className={`bg-[#e1dfe0] relative rounded-b-3xl pt-8 max-sm:pt-8 w-full px-16 max-sm:px-4 transition-all duration-500`}>
      <div className='mx-auto lg:max-w-7xl'>
        <div className='justify-between flex'>
          <div className={`${isContentVisible ? "w-2/3 max-sm:w-[60%]":"w-3/4 max-sm:w-[60%]"} transition-all duration-300 ease-in-out mb-12 max-sm:mb-0`}>
            <div ref={nameRef} className='text-left items-center mb-2 max-sm:mb-1'>
              <div className='max-sm:space-y-1 mb-2 max-sm:mb-0 items-center justify-between flex'>
                <p className='text-[#000] font-[TATAAIARegularPrint] text-[34px] max-sm:text-xl not-italic font-bold leading-[normal]'>{ profileData?.microsite?.name }</p>
                <div onClick={()=>{setModalIsOpen(true)}} className='bg-[#D62049] h-[35px] w-[35px] max-sm:h-[30px] max-sm:w-[30px] cursor-pointer pl-2 pr-3 py-2.5 max-sm:py-1.5 rounded-full'>
                  <ShareFill className='w-[16px] text-white h-[16px] max-sm:w-[14px] bg-transparent'/>
                </div>
              </div>
              <p className='text-[#0073BB] font-[TATAAIARegularPrint] text-[20px] max-sm:text-sm not-italic capitalize font-semibold leading-[130%]'>{ profileData?.microsite?.designation }</p>
            </div>
            <p ref={descriptionRef} className='text-[#444] font-[HelveticaNeueLTStd] text-sm max-sm:text-xs not-italic text-left font-normal leading-[120%] transition-height duration-500 ease-in-out overflow-hidden h-[3.8rem] max-sm:h-[6.5rem]'>
              { profileData?.microsite?.about?.slice(0,isContentVisible ? 320 : 400) }{profileData?.microsite?.about?.length > (isContentVisible ? 320 : 400) && "..."}
            </p>
            
            <div className={`w-[30%] max-sm:w-full max-sm:my-4 mt-4 transition-all duration-300 ease-in-out overflow-hidden ${isContentVisible ? "height-adjust":"h-0"}`}>
              <SmallCarousel conventionData={profileData?.achivements?.data}/>
            </div>
            
            <div className={`flex max-sm:hidden gap-4 my-2 transition-all duration-300 ease-in-out overflow-hidden`} ref={contentRef} style={{ height: isContentVisible ? 'auto' : '0' }}>
              {profileData?.partner_achivements?.data?.slice(0,4)?.map((acheive, a_ind)=>{
                return (
                  <div key={a_ind} className={`gradient-shadow bg-white m-2 w-24 h-22 max-sm:w-16 max-sm:h-16 space-y-0  ${(a_ind == 1 || a_ind == 2) ? "px-4":"px-1"} p-3 py-3.5 rounded-[35px] rounded-bl-none rounded-tr-none`}>
                    <span className='font-semibold text-[#0073BB] text-xl'>{ acheive?.value }</span>
                    <p className='text-xs capitalize font-[TATAAIAPrint] text-[#000] font-semibold text-[10px]'>{ acheive?.key }</p>
                  </div>
                );
              })}
            </div>
            
            {!isShow && <div className='flex max-sm:hidden my-6 w-fit text-left p-4 gap-8 bg-[#0073BB] rounded-lg'>
              <p className='text-[14px] font-[TATAAIAPrint] text-[#fff]'>Want to join my team?</p>
              <div className='flex items-center gap-2 cursor-pointer' onClick={()=>{setOpen(true)}}>
                <span className='text-[#ffff00] font-[TATAAIARegularPrint] text-[15px]'>Join now</span>
                <ArrowRight className='text-[#ffff00] text-[22px]'/>
              </div>
            </div>}
            
            {!isShow && <div
              className={`max-sm:hidden font-[TATAAIARegularPrint] transition-opacity duration-300 my-6 ease-in-out w-full flex items-center gap-3 text-[#D62049] cursor-pointer text-xs not-italic font-bold leading-[normal] tracking-[-0.36px] uppercase ${isContentVisible ? 'opacity-100' : 'opacity-0'}`}
              onClick={() => {
                const newUrl = `/profile?${queryParams?.toString()}`
                navigate(newUrl);
              }}
            >
              <span>Full Profile</span>
              <ChevronRight className='text-xs' />
            </div>}
          </div>
          <div className="w-1/4 max-sm:w-[35%] pt-4 max-sm:pt-0 flex justify-end">
            <div className={`relative transition-all duration-500 ease-in-out ${!isSmall ? 'w-full h-auto' : 'w-32 h-32 rounded-full overflow-hidden'}`}>
              <div className={`absolute inset-0 bg-transparent transition-opacity duration-500 ease-in-out ${!isSmall ? 'opacity-0' : 'opacity-100'} rounded-full`}></div>
              <img
                src={profileData?.microsite?.profile_image}
                alt="Example"
                className={`transition-transform gradient-shadow-header duration-500 ease-in-out rounded-3xl ${
                  !isSmall
                    ? 'w-full h-auto object-cover transform scale-110'
                    : 'w-full h-32 object-cover transform scale-100'
                }`}
                style={{
                  objectPosition: 'top', // Ensure image expands from the top
                  transformOrigin: 'top', // Set transformation origin to top
                }}
              />
            </div>
          </div>
        </div>
        <div className={`max-sm:flex hidden transition-all duration-300 ease-in-out overflow-hidden`} ref={contentRef} style={{ height: isContentVisible ? 'auto' : '0' }}>
        {profileData?.partner_achivements?.data?.slice(0,4).map((acheive, a_ind)=>{
            return (
              <div key={a_ind} className={`gradient-shadow bg-white m-1 w-[5rem] h-[4.5rem] space-y-0 p-3 ${(a_ind == 1 || a_ind == 2) ? "px-4":"px-1"} rounded-[35px] rounded-bl-none rounded-tr-none`}>
                  <span className='font-semibold text-[#0073BB] max-sm:text-sm text-2xl mb-4'>{acheive?.value}</span>
                  <p className='text-sm font-semibold max-sm:text-[8.5px] font-[TATAAIAPrint] capitalize text-[#000] max-sm:leading-3'>{ acheive?.key}</p>
              </div>
            );
          })}  
        </div>
        
        {!isShow && <div className='hidden max-sm:flex mx-auto max-sm:justify-between w-fit text-left p-4 mt-8 gap-8 bg-[#0073BB] rounded-lg'>
          <p className='text-sm font-[TATAAIAPrint] text-[#fff]'>Want to join my team?</p>
          <div className='flex items-center gap-2 uppercase cursor-pointer' onClick={()=>{setOpen(true)}}>
            <span className='text-[#ffff00] text-[15px] font-[TATAAIARegularPrint]'>Join Now</span>
                <ArrowRight className='text-[#ffff00] text-[22px]'/>
          </div>
        </div>}
        
        {!isShow && <div
          className={`pt-8 mb-12 font-[TATAAIARegularPrint] max-sm:flex hidden transition-opacity duration-300 ease-in-out w-full justify-center items-center gap-3 text-[#D62049] cursor-pointer text-xs not-italic font-bold leading-[normal] tracking-[-0.36px] uppercase ${isContentVisible ? 'opacity-100' : 'opacity-0'}`}
          onClick={() => {
            const newUrl = `/profile?${queryParams?.toString()}`
            navigate(newUrl);
          }}
        >
          <span>Full Profile</span>
          <ChevronRight className='text-xs' />
        </div>}
        <span
          onClick={toggleSize}
          className={`${isSmall ? "bg-[#0073BB] -bottom-5" : "bg-[#D62049] -bottom-5 left-1/2"} transform -translate-x-1/2 cursor-pointer absolute rounded-full`}
        >
          <ArrowDown className={`p-2 text-[40px] text-white transition-all ${isSmall ? "text-white " : "text-dark"} duration-500 ease-in-out ${!isSmall ? "rotate-180" : ""}`} />
        </span>
        
        <LeadForm open={open} handleClose={handleClose} formType="join advisor" source={"Join Now"} />
        <ShareModal modalIsOpen={ modalIsOpen } handleClose={handleClose} url={window.location.origin + window.location.pathname + "profile/"} />
      </div>
    </div>
  );
}
