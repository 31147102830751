import axios from "axios";

let tempUrl = `${window.location.href}`;
let setUrl;

if (tempUrl.search("localhost") > 0) {
  setUrl = "https://rishabhvatts.spdevmfp.com/";
} else{
  setUrl = window.location.origin;
}

export default axios.create({
  baseURL: setUrl,
});
